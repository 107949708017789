//@flow
import { Map } from 'immutable';
import { APP_ACTIONS } from './../actions/actionTypes';
import { isMobile } from '../utils/common-utils';


const initialState = Map({  
  sidePanelToggle: isMobile(),
  selectedMenus: ['dashboard']
});

export default function appConfigState(state = initialState, action) {
  switch(action.type){
    case APP_ACTIONS.TOGGLE_SIDEPANEL:
      return state.set("sidePanelToggle", !state.get('sidePanelToggle'));
    case APP_ACTIONS.SET_SELECTED_MENU:
      return state.set("selectedMenus", action.payload);     
    default: 
      return state;
  }
}