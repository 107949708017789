import React, { Component } from 'react';
import { Icon, Input, AutoComplete } from 'antd';
import '../styles/components/search.scss';

const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup

class Search extends Component {
    generateOptions(options) {
        return options.map((group, i) => (
            <OptGroup
                key={`${group.title}-${i}`}
                label={group.title}
            >
                {group.children.map((opt, j) => (
                    // value needs to be unique
                    <Option key={`${opt.id}-${i}${j}`} value={`${opt.id}-${i}${j}`} label={opt.title}>
                        {opt.title}
                    </Option>
                ))}
            </OptGroup>
        ));
    }

    getId = (value)=>{
        // remove appended keys
        return value.substring(0, value.length - 3);
    }

    onSelect = (value, opt) => {
        this.props.handleSelect(this.getId(value));
    }

    handleSearch = (value) => {
        this.props.handleSearch(value);
    }

    render() {
        return (
            < AutoComplete
                className="certain-category-search"
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: 300 }}
                style={{ width: '100%' }}
                dataSource={this.generateOptions(this.props.options)}
                placeholder={this.props.searchText}
                optionLabelProp="label"
                onSelect={this.onSelect}
                onSearch={this.handleSearch}
            >
                <Input suffix={<Icon type="search" className="certain-category-icon" />} />
            </AutoComplete >
        )
    }
}

export default Search;