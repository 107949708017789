import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Form, Icon, Input, Button, Row, Col, message } from 'antd';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';

import { authenticate } from '../actions/loginActions';

const FormItem = Form.Item;

class LoginFormView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loginInProgress: false
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        message.loading('Please wait', 0);
        await this.setState({loginInProgress:true});
        const errMsg = await this.props.authenticate(values.username, values.password);        
        message.destroy();
        if (errMsg) {
          message.error(errMsg);
          await this.setState({loginInProgress:false});
        }
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Layout>

        <div className='pure-g epar__login__form-header'>
          <div className='pure-u-1-1'>
            <div className='pure-g'>
              <div className='pure-u-1'>
                <img src='/assets/logo.png' className="epar__login__form--logo" alt='' />
              </div>
            </div>
            <div className='pure-g epar__login__form--label'>
              <div className='pure-u-1 epar__content--center'>
                Log into your account
              </div>
            </div>
          </div>
        </div>

        <Form onSubmit={this.handleSubmit} className="epar__login__form">
          <FormItem label='' className='epar__login-form__field'>
            {getFieldDecorator('username', {
              rules: [
                {
                  required: true,
                  message: 'Please input your email!'
                },
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!'
                }
              ]
            })(
              <Input
                prefix={< Icon type="user" style={{ fontSize: 13 }} />}
                placeholder="email" />
            )}
          </FormItem>

          <FormItem label='' className='epar__login-form__field'>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your Password!'
                }
              ]
            })(
              <Input
                data-testid="password"
                prefix={< Icon type="lock" style={{ fontSize: 13 }} />}
                type="password"
                placeholder="Password" />
            )}
            <div className='epar__login__form__container__forgot-password' ><Link to='forgot_password'><a href='# '>I can't remember my password</a></Link></div>
          </FormItem>

          <FormItem className="epar__content--center">
            <Row justify='center' type='flex'>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="epar__login-form__submit--button"
                  disabled= {this.state.loginInProgress}>
                  Log in
                </Button>
              </Col>
            </Row>
          </FormItem>          
        </Form>
      </Layout>
    )
  }
}
function mapStateToProps(state, props) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    authenticate: bindActionCreators(authenticate, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(LoginFormView)));
