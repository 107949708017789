/**
 * configure aws amplify client
 */

import Amplify from 'aws-amplify';
import { CONFIG } from '../config/app-config';
import { ContractorApi } from './api-utils';

const { cognitoUserPoolId, userPoolWebClientId, cognitoIdentityPoolId, awsRegion, baseApiUrl, apiStage, documentsUploadsBucket } = CONFIG;

export function awsConfigInitialise() {

    Amplify.configure({
        Auth: {
            // REQUIRED - Amazon Cognito Identity Pool ID
            identityPoolId: cognitoIdentityPoolId,
            // REQUIRED - Amazon Cognito Region
            region: awsRegion,
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: cognitoUserPoolId,
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: userPoolWebClientId,
            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or
            // not
            mandatorySignIn: true,
            // OPTIONAL - Configuration for cookie storage cookieStorage: { // REQUIRED -
            // Cookie domain (only required if cookieStorage is provided)     domain:
            // '.yourdomain.com', // OPTIONAL - Cookie path     path: '/', // OPTIONAL -
            // Cookie expiration in days     expires: 365, // OPTIONAL - Cookie secure flag
            //    secure: true }
        },
        Storage: {
            bucket: documentsUploadsBucket, //Your bucket ARN;
            region: awsRegion, //Specify the region your bucket was created in;
        },
    });
}

export async function awsAmplifyApiInit() {
    await Amplify.configure({
        API: {
            endpoints: [
                {
                    name: ContractorApi.ADMIN_API,
                    endpoint: `${baseApiUrl}admin-api/${apiStage}`
                },
                {
                    name: ContractorApi.COMMON_API,
                    endpoint: `${baseApiUrl}common-api/${apiStage}`
                },
                {
                    name: ContractorApi.COURSE_API,
                    endpoint: `${baseApiUrl}course-api`
                },
                {
                    name: ContractorApi.DOCUMENT_API,
                    endpoint: `${baseApiUrl}document-api/${apiStage}`
                },
                {
                    name: ContractorApi.USER_API,
                    endpoint: `${baseApiUrl}user-api/${apiStage}`
                },
                {
                    name: ContractorApi.REQUIREMENT_API,
                    endpoint: `${baseApiUrl}req-api`
                }
            ]
        }
    });
}
