import React from 'react';
import { ListItem } from '../../components/ListItemBuilder';
import { Row } from 'antd';
import moment from 'moment';
import { getContractorDocLink, getUserDocLink } from './actions/documentActions';
import { openFile} from '../../utils/file-utils'

const viewDocument = (docId, isUserDoc, s3Version) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Row>
                <img src="/assets/icons/file.svg" alt="" style={{ height: 40 }} />
            </Row>
            <Row>
                <a href="# ">
                    <span onClick={e=>openDoc(docId, isUserDoc, s3Version)}>View PDF</span>
                </a>
            </Row>
        </div>
    )
}

const openDoc = async (docId, isUserDoc, s3Version) => {
    let link;
    if(isUserDoc){
        link = await getUserDocLink(docId, s3Version);
    }else{
        link = await getContractorDocLink(docId, s3Version);        
    }
    openFile(link);
}

export class DocumentListItem {
    static get Builder() {
        class Builder {
            constructor(document, isUserDoc=false) {
                this.document = document;
                this.isUserDoc = isUserDoc;
            }
            build() {

                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(this.document.title, `/documents/${this.isUserDoc ? 'user_document':'contractor_document'}/${this.document.id}`)
                    .setDescription(
                        <div>
                            <Row>{`Category: ${this.document.category}`}</Row>
                            <Row>{`Date Added: ${moment(this.document.date_added).format('DD/MM/YYYY')}`}</Row>
                            <Row>{`Version: ${this.document.latest_version}`}</Row>
                        </div>
                    )
                    .setRightIcon(viewDocument(this.document.id, this.isUserDoc));
                return builder.build();
            }
        }
        return Builder;
    }
}

export class DocumentVersionListItem {
    static get Builder() {
        class Builder {
            constructor(version, isUserDoc) {
                this.version = version;
                this.isUserDoc = isUserDoc;
            }
            build() {
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitle(`Version ${this.version.version}`)
                    .setDescription(
                        <div>
                            <Row>{`Date Added: ${moment(this.version.created_at).format('DD/MM/YYYY')}`}</Row>
                        </div>
                    )
                    .setRightIcon(viewDocument(this.version.document_id, this.isUserDoc, this.version.s3_version));
                return builder.build();
            }
        }
        return Builder;
    }
}