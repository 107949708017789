import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Button, message, Modal } from 'antd';

import NewUserModal from './NewUserModal';
import { loadUserList, activateUser, deactivateUser } from '../actions/userActions';
import Search from '../../../components/Search';
import { ListWidget } from '../../../components/ListWidget';
import * as SearchUtils from '../../../utils/search-utils';
import { UserListItem } from '../UserListItemBuilder';
import { setBreadcrumb } from '../../../actions/commonActions';
import { USER_DEACTIVATE_FILTER } from '../../organisations/constants';

const confirm = Modal.confirm;

class UserManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isNewUsermodalVisible: false,
            searchMatches: null,
            selectedUsers: new Set(),
            isActivateButtonDisabled: true,
            isDeactivateButtonDisabled: true
        };
        this.loadUserList();
        this.props.setBreadcrumb("Users", [
            { route: '', label: 'Users'}
        ]);
    }
    
    loadUserList = async () => {
        this.props.loadUserList();
    }

    getUsersForListView = () => {
        let userList = this.filterUserss().map((user) => {
            let builder = new UserListItem.Builder(user, this.onUserSelection, this.state.selectedUsers.has(user.user_id));
            return builder.build();
        });
        return userList;
    }

    filterUserss = () => {
        return SearchUtils.filter(this.props.users ? this.props.users : [], "user_id", this.state.searchMatches);
    }

    filterSearchOptions() {
        const users = this.filterUserss();
        const optionsMap = { title: "Users", children: [] };
        users.forEach((user) => {
            optionsMap.children.push({ title: `${user.first_name} ${user.last_name}`, id:user.user_id });
        });

        return [optionsMap];
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.userIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    onUserSelection = (userId, isSelected) => {
        let selectedUsers = this.state.selectedUsers;
        if(isSelected){
            selectedUsers.add(userId);
        }else{
            selectedUsers.delete(userId);
        }
        this.setState({selectedUsers});
    }
    onSearchSelect = (userId) => {
    }
    
    handleDeactivateUser = async () => {
        const selectedUsers = this.props.users.filter(user=>this.state.selectedUsers.has(user.user_id));
        const adminUser = selectedUsers.filter(user => user.role === USER_DEACTIVATE_FILTER);
        const deactivateUserList = async () => {
            for(let i=0; i<selectedUsers.length; i++){
                const user = selectedUsers[i];
                if(user.status==='ACTIVE'){
                    await deactivateUser(user.user_id, user.email);
                    message.success(`User ${user.first_name} ${user.last_name} is deactivated`);
                }else{
                    message.warn(`User ${user.first_name} ${user.last_name} is already inactive`);
                }
            }
            this.loadUserList();
        }

        if (adminUser.length > 0) {
            confirm({
                title: 'Are you sure you want to deactivate the Contractor Admin User? Deactivating this User will permanently disable their access and  will no longer be able to log in.',
                okText: 'Yes',
                cancelText: 'No',
                onOk: deactivateUserList,
            })
        } else {
            deactivateUserList();
        }   
    };

    handleActivateUser = async () => {
        const selectedUsers = this.props.users.filter(user=>this.state.selectedUsers.has(user.user_id));
        for(let i=0; i<selectedUsers.length; i++){
            const user = selectedUsers[i];
            if(user.status==='INACTIVE'){
                await activateUser(user.user_id, user.email);
                message.success(`User ${user.first_name} ${user.last_name} is activated`);
            }else{
                message.warn(`User ${user.first_name} ${user.last_name} is already active`);
            }
        }
        this.loadUserList();
    };

    render() {
        return (
            <div>
                {this.state.isNewUsermodalVisible &&
                    <NewUserModal
                        visible={this.state.isNewUsermodalVisible}
                        onCancel={() => this.setState({ isNewUsermodalVisible: false })}
                        onSave={() => {
                            this.loadUserList();
                            this.setState({ isNewUsermodalVisible: false })
                        }}
                    />
                }
                <div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            handleSelect={(value) => this.onSearchSelect(value)}
                            options={this.filterSearchOptions()}
                        />
                    </div>
                    <div className="pure-u-1 pure-u-md-2-3">

                        <Button.Group className='add-org-unit-type-btn  epar__ouadmin__ouview--outype-button'>
                            {this.props.orgList.length > 0 &&
                                <Button type='primary' className='add-org-unit-type-btn' onClick={e => this.setState({ isNewUsermodalVisible: true })}>
                                    Add New User
                                </Button>
                            }
                            <Button type='danger' className='add-org-unit-type-btn' onClick={this.handleActivateUser} disabled={this.state.selectedUsers.size===0} >
                                Activate
                            </Button>
                            <Button type='danger' className='add-org-unit-type-btn' onClick={this.handleDeactivateUser} disabled={this.state.selectedUsers.size===0} >
                                Deactivate
                            </Button>
                        </Button.Group>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getUsersForListView()} loading={this.props.users === undefined} />
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state, props) {
    return {
        users: state.getIn(['userState', 'users']),
        userIndex: state.getIn(["userState", "userIndex"]),
        orgList: state.getIn(['loginUserState', 'orgList'])
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadUserList: bindActionCreators(loadUserList, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
