import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';
import { ListWidget } from '../../../components/ListWidget';
import Search from '../../../components/Search';
import { DocumentListItem } from '../DocumentListItemBuilder';
import NewDocumentModal from '../components/NewDocumentModal';
import { loadContractorDocs } from '../actions/documentActions';
import * as SearchUtils from '../../../utils/search-utils';

class ContractorDocumentView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isNewDocumentModalVisible: false,
            searchMatches: null,
            docList: [],
            loading:true
        }
        this.loadDocList();
    }

    loadDocList = async() => {
        this.props.loadContractorDocs();
    }

    getDocumentsForListView = () => {
        let documentList = this.filterDocuments().map((doc) => {
            let builder = new DocumentListItem.Builder(doc);
            return builder.build();
        });
        return documentList;
    }

    handleNewDocumentModalClose = () => {
        this.setState({ isNewDocumentModalVisible: false });
    }

    handleNewDocumentModalSave = () => {
        this.setState({ isNewDocumentModalVisible: false });
        this.loadDocList();
    }

    openNewDocumentModal = () => {
        this.setState({ isNewDocumentModalVisible: true });
    }

    filterDocuments = () => {
        return SearchUtils.filter(this.props.documents ? this.props.documents : [], "id", this.state.searchMatches);
    }

    filterSearchOptions() {
        const docs = this.filterDocuments();
        const optionsMap = { title: "Documents", children: [] };
        docs.forEach((doc) => {
            optionsMap.children.push({ title: doc.title, id: doc.id });
        });

        return [optionsMap];
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.documentIndex, phrase);
        this.setState({ searchMatches: matches });
    }

    onSearchSelect = (dId) => {
        this.setState({ searchSelectedDocumentId: dId });
    }

    goToSelectedDocument = () => {
        if (this.state.searchSelectedDocumentId) {
            //    return (<Redirect to={`/app/contractors/contractor_details/${this.state.searchSelectedContractorId}`} />);
        }
        return null;
    }

    render() {
        return (
            <div>
                <NewDocumentModal
                    visible={this.state.isNewDocumentModalVisible}
                    onCancel={this.handleNewDocumentModalClose}
                    onSave={this.handleNewDocumentModalSave}
                />
                <div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            handleSelect={(value) => this.onSearchSelect(value)}
                            options={this.filterSearchOptions()}
                        />
                    </div>
                    <div className="pure-u-1 pure-u-md-2-3">
                        <Button style={{ marginLeft: 10 }} className='epar__tasks__action--button'>
                            <img src="/assets/icons/shop.svg" alt="" style={{ height: 18, marginRight: 5 }} />
                            {"epar Store"}
                        </Button>
                        <Button onClick={() => this.openNewDocumentModal()} className='epar__tasks__action--button'>
                            {"Add New Document"}
                        </Button>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getDocumentsForListView()} loading={this.props.documents===undefined} />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        documents: state.getIn(["documentState", "documents"]),
        documentIndex: state.getIn(["documentState", "documentIndex"])
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadContractorDocs: bindActionCreators(loadContractorDocs, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ContractorDocumentView);