import React, { Component } from 'react';
import { Form, Button, message, Card } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import PDFViewer from './../../../components/PDFViewer';
import ConnectLoading from '../../../components/ConnectLoading';
import { getDocReview, submitDocReview } from '../actions/documentActions';
import { setSelectedMenu } from '../../../actions/commonActions';
import { setBreadcrumb } from '../../../actions/commonActions';

class DocumentReviewView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            docReview: undefined
        }
        this.loadDocUrl();
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if(this.state.docReview !== prevState.docReview || this.props.orgList !== prevProps.orgList){
            this.updateBreadCrumb();
        }
    }

    updateBreadCrumb = () => {
        if (this.state.docReview && this.state.docReview.org_id) {
            const org = this.props.orgList.find(org=>org.org_id===this.state.docReview.org_id)
            if(org){
                const orgName = org.org_name;
                this.props.setBreadcrumb("Document Review", [
                    { route: '#', label: 'Organisations'},
                    { route: `/org/${this.state.docReview.org_id}`, label: orgName},
                    { route: '', label: 'Document Review'},
                ]);
            }
        }
    }

    loadDocUrl = async () => {
        const docReview = await getDocReview(this.props.reqId, this.props.isSiteReq);
        this.setState({ docReview });
        this.props.setSelectedMenu([docReview.org_id]);
    }

    onReviewComplete = async () => {
        message.loading('Submitting review...', 0);
        await submitDocReview(this.props.reqId, this.props.isSiteReq, this.state.docReview.id);
        message.destroy();
        message.success('Successfully submitted');
        this.props.history.push(`/org/${this.state.docReview.org_id}`);
    }

    render() {
        const { docReview } = this.state;
        if (!docReview) {
            return <ConnectLoading />
        }
        return (
            <Card>
                <div>
                    <PDFViewer docContentUrl={docReview.url} fileName={docReview.doc_title} />

                    {docReview.status!==100 &&
                        <Button onClick={this.onReviewComplete} className='epar__tasks__action--button'>
                            {"Complete Review"}
                        </Button>
                    }
                </div>
            </Card>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['loginUserState', 'orgList']),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(DocumentReviewView)));