//@flow
import { getApi, ContractorApi, putApi } from "../../../utils/api-utils";

export async function getOrgCourse(reqId, isSiteReq) {
  try {
      const data = await getApi(ContractorApi.COURSE_API, `/course`, { queryStringParameters: { reqId, isSiteReq } });
      return data.request;
  } catch (e) {
      console.log(e);
  }
}

export async function submitAnswers(orgId, courseId, answers, reqId, courseReqId, isSiteReq) {
  try {
      const data = await putApi(ContractorApi.COURSE_API, `/answers`, { orgId, courseId, answers, reqId, isSiteReq, courseReqId });
      return data;
  } catch (e) {
      console.log(e);
  }
}
