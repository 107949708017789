import React from 'react';
import { Form, Radio, Card, Collapse, Button } from 'antd';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Panel = Collapse.Panel;

export const CourseQuizForm = Form.create()((props) => {
  const { getFieldDecorator } = props.form;
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };
  return (
    <Card title="Quiz">
      <Form layout="vertical">
        <Collapse accordion activeKey={props.activeQuestion} onChange={props.onActiveQuestionChange}>
          {props.questions.map((question, index) =>
            <Panel header={`Question ${index + 1}`} key={`${index}`}>
              <FormItem label={question.question}  >
                {getFieldDecorator(`${index}`, {
                  rules: [{ required: true, message: 'Please select an answer' }]
                }
                )(
                  <RadioGroup>
                    {question.options.map((option, i) => <Radio value={option} key={i} style={radioStyle} >{option}</Radio>)}
                  </RadioGroup>
                )}
              </FormItem>
              <div className="pure-g epar__group-wiz__nav-buttons__container__left">
                {index > 0 && <Button type="primary" onClick={props.prevQuestion}>Previous</Button>}
                {index < props.questions.length - 1 && <Button type="primary" onClick={props.nextQuestion}>Next</Button>}
              </div>
            </Panel>
          )}
        </Collapse>
      </Form>
    </Card>
  );
});
