import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Form, Modal, Input, message, TreeSelect } from 'antd';

import { updateUserinfo } from '../actions/userActions';

const FormItem = Form.Item;
const TreeNode = TreeSelect.TreeNode;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class EditUserModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            savingChanges: false
        }
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            await this.setState({ savingChanges: true });
            message.loading('Saving changes...');
            const result = await updateUserinfo(this.props.user.user_id, values.firstName, values.lastName, values.role, values.organisations);
            message.destroy();
            if (result.error) {
                message.error(result.error, 5);
                await this.setState({ creatingUser: false });
            } else {
                message.success('User updated successfully');
                form.resetFields();
                this.props.onSave();
            }
        });
    }

    render() {
        const { form, orgList } = this.props;
        const { getFieldDecorator } = form;
        const { first_name, last_name, role, organisations } = this.props.user;
        let orgToDisplay = [];

        organisations.forEach(oid=>{
            orgList.forEach(org=>{
                if(oid===org.org_id) orgToDisplay.push(oid);
            });
        });

        return (
            <Modal
                title={"Add New User"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
                okButtonProps={{ disabled: this.state.savingChanges }}
                cancelButtonProps={{ disabled: this.state.savingChanges }}
            >
                <Form layout='horizontal'>
                    <FormItem {...formItemLayout} label={"First Name"}>
                        {
                            getFieldDecorator('firstName', { initialValue:first_name, rules: [{ required: true, message: 'Please enter first name' }] })(
                                <Input maxLength={100} placeholder={"Enter first name"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Last Name"}>
                        {
                            getFieldDecorator('lastName', { initialValue:last_name,  rules: [{ required: true, message: 'Please enter last name' }] })(
                                <Input maxLength={100} placeholder={"Enter last name"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Organisation"} extra="Assign this user to an organisation they will be working with.">
                        {
                            getFieldDecorator('organisations', { initialValue:orgToDisplay })(
                                <TreeSelect
                                    showSearch
                                    placeholder="Please select"
                                    allowClear
                                    multiple
                                    treeDefaultExpandAll
                                >
                                    {orgList.map(org => <TreeNode value={org.org_id} title={org.org_name} key={org.org_id} />)}

                                </TreeSelect>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Role"}>
                        {
                            getFieldDecorator('role', { initialValue:role} )(
                                <Input maxLength={100} placeholder={"Enter role of the user"} />
                            )
                        }
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['loginUserState', 'orgList'])
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditUserModal));