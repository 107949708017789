import React from 'react';
import { ListItem } from '../../components/ListItemBuilder';
import { Row } from 'antd';
import { REQUIREMENT_CATEGORY } from './constants';

export class RequestListItem {
    static get Builder() {
        class Builder {
            constructor(request) {
                this.request = request;
            }

            getTitle = () => {
                switch (this.request.category) {
                    case REQUIREMENT_CATEGORY.DOC_UPLOAD:
                        return 'Document Upload';
                    case REQUIREMENT_CATEGORY.DOC_REVIEW:
                        return 'Document Review';
                    case REQUIREMENT_CATEGORY.COURSE:
                        return 'Online Training';
                    default:
                        return '';
                }
            }

            getLink = () => {
                switch (this.request.category) {
                    case REQUIREMENT_CATEGORY.DOC_UPLOAD:
                        return `/documents/document_upload/${this.request.id}`;
                    case REQUIREMENT_CATEGORY.DOC_REVIEW:
                        return `/documents/document_review/${this.request.id}`;
                    case REQUIREMENT_CATEGORY.COURSE:
                        return `/course/${this.request.id}`;
                    default:
                        return '';
                }
            }

            build() {

                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(this.getTitle(), this.getLink())
                    .setDescription(
                        <div>
                            <Row>{this.request.category === REQUIREMENT_CATEGORY.COURSE ? 'Course Title: ' : 'Document Title: '}{this.request.title}</Row>
                        </div>
                    )
                    .setRightIcon(getStatusIcon(this.request.status, this.request.category));
                return builder.build();
            }
        }
        return Builder;
    }
}



export class SiteRequirementListItem {
    static get Builder() {
        class Builder {
            constructor(request) {
                this.request = request;
            }

            getTitle = () => {
                switch (this.request.category) {
                    case REQUIREMENT_CATEGORY.DOC_UPLOAD:
                        return 'Document Upload';
                    case REQUIREMENT_CATEGORY.DOC_REVIEW:
                        return 'Document Review';
                    case REQUIREMENT_CATEGORY.COURSE:
                        return 'Online Training';
                    default:
                        return '';
                }
            }

            getLink = () => {
                switch (this.request.category) {
                    case REQUIREMENT_CATEGORY.DOC_UPLOAD:
                        return `/documents/site_document_upload/${this.request.id}`;
                    case REQUIREMENT_CATEGORY.DOC_REVIEW:
                        return `/documents/site_document_review/${this.request.id}`;
                    case REQUIREMENT_CATEGORY.COURSE:
                        return `/site_course/${this.request.id}`;
                    default:
                        return '';
                }
            }

            build() {
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(this.getTitle(), this.getLink())
                    .setDescription(
                        <div>
                            <Row><b>Assigned To: </b>{this.request.assigned_to}</Row>
                            <Row><b>{this.request.category === REQUIREMENT_CATEGORY.COURSE ? 'Course Title: ' : 'Document Title: '}</b>{this.request.title}</Row>
                        </div>
                    )
                    .setRightIcon(getStatusIcon(this.request.status, this.request.category));
                return builder.build();
            }
        }
        return Builder;
    }
}

const getStatusIcon = (status, category) => {
    let icon, color, text;
    switch (status) {
        case 0:
            icon = 'danger.svg';
            color = '#E2574C';
            text = category === REQUIREMENT_CATEGORY.DOC_UPLOAD ? 'Missing' : 'To-Do';
            break;
        case 50:
            icon = 'pending.svg';
            color = '#ffc200';
            text = 'Pending';
            break;
        case 100:
            icon = 'checked.svg';
            color = '#3DB39E';
            text = category === REQUIREMENT_CATEGORY.DOC_UPLOAD ? 'Verified' :
                category === REQUIREMENT_CATEGORY.COURSE ? 'Compliant' : 'Done';
            break;
        case -1:
            icon = 'cancel.svg';
            color = '#E2574C';
            text = category === REQUIREMENT_CATEGORY.DOC_UPLOAD ? 'Rejected' : 'Not Compliant';
            break;
        case -2:
            icon = 'cancel.svg';
            color = '#E2574C';
            text = category === REQUIREMENT_CATEGORY.DOC_UPLOAD ? 'Expired' : 'Not Compliant';
            break;
        default:
            break;
    }

    return (
        <div style={{ width: '70px'}}>
            <Row>
                <img src={`/assets/icons/${icon}`} alt="" style={{ height: 40, display: 'block', margin: 'auto' }} />
            </Row>
            <Row style={{ textAlign: 'center' }}>
                <span style={{ color: color }}>{text}</span>
            </Row>
        </div>

    )
}