import { ContractorApi, postApi, getApi, putApi } from '../../../utils/api-utils';
import { USER_ACTIONS } from './actionTypes';
import { message } from 'antd';

export async function addNewUser(firstName, lastName, email, role, organisations) {
  try {
    const response = await postApi(ContractorApi.ADMIN_API, '/add_user', { firstName, lastName, email, role, organisations });
    return response.userId;
  } catch (err) {
    return { error: err.message }
  }
}

export function loadUserList() {
  return async function (dispatch, getState) {
    try {
      const result = await getApi(ContractorApi.ADMIN_API, '/user_list');
      await dispatch({
        type: USER_ACTIONS.USER_LIST,
        payload: result.userList
      });
    } catch (e) {
      console.log(e);
      message.error("Error in getting user list");
    }
  }
}

export async function getUserInfo(userId){
  try {
    const result = await getApi(ContractorApi.ADMIN_API, '/user_info', { queryStringParameters: { userId } });
    return result.userInfo;

  } catch (e) {
    console.log(e);
    message.error("Error in getting user info");
  }
}


export async function updateUserinfo(userId, firstName, lastName, role, organisations) {
  try {
    const response = await putApi(ContractorApi.ADMIN_API, '/update_user', { firstName, lastName, userId, role, organisations });
    return response.userId;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function deactivateUser(userId, userName) {
  try {
    const response = await putApi(ContractorApi.ADMIN_API, '/deactivate_user', { userId, userName });
    return response.userId;
  } catch (err) {
    console.log(err);
    message.error("Error deactivating user");
  }
}

export async function activateUser(userId, userName) {
  try {
    const response = await putApi(ContractorApi.ADMIN_API, '/activate_user', { userId, userName });
    return response.userId;
  } catch (err) {
    console.log(err);
    message.error("Error activating user");
  }
}