import React, { Component } from 'react';
import { Card, Form, Select, DatePicker, Button, message } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { loadContractorDocs, getOrgDocRequest, completeOrgDocRequest, loadUserDocs, DOC_REQUEST_STATUS } from '../actions/documentActions';
import NewDocumentModal from '../components/NewDocumentModal';
import ConnectLoading from '../../../components/ConnectLoading';
import { setSelectedMenu } from '../../../actions/commonActions';
import { setBreadcrumb } from '../../../actions/commonActions';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};

const formTailLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 15, offset: 4 },
};

const Option = Select.Option;

class DocumentUploadRequirementView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isNewDocumentModalVisible: false,
            request: undefined
        }
        this.loadDocList();
        this.loadRequest();
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if(this.state.request !== prevState.request || this.props.orgList !== prevProps.orgList){
            this.updateBreadCrumb();
        }
    }

    updateBreadCrumb = () => {
        if (this.state.request && this.state.request.org_id) {
            const org = this.props.orgList.find(org=>org.org_id===this.state.request.org_id)
            if(org){
                const orgName = org.org_name;
                this.props.setBreadcrumb("Document Upload", [
                    { route: '#', label: 'Organisations'},
                    { route: `/org/${this.state.request.org_id}`, label: orgName},
                    { route: '', label: 'Document Upload'},
                ]);
            }
        }
    }

    loadDocList = async () => {
        if(this.props.isSiteReq){
            this.props.loadUserDocs();
        }else{
            this.props.loadContractorDocs();
        }
    }

    loadRequest = async () => {
        const request = await getOrgDocRequest(this.props.reqId, this.props.isSiteReq);
        this.setState({ request });
        this.props.setSelectedMenu([request.org_id]);
    }

    renderRequirementStatus = () => {
        switch(this.state.request.status){
            case DOC_REQUEST_STATUS.NEW :{
                return (
                    <span>
                        <span>{"Missing"}</span> - {"You must select an appropriate document below, add the expiry date and submit for verification."}
                    </span>
                )
            }
            case DOC_REQUEST_STATUS.COMPLETED:{
                return (
                    <span>
                        <span>{"Pending Verification"}</span>
                    </span>
                )
            }
            case DOC_REQUEST_STATUS.VERIFIED:{
                return (
                    <span>
                        <span>{"Verified"}</span>
                    </span>
                )
            }
            case DOC_REQUEST_STATUS.REJECTED:{
                return (
                    <span>
                        <span>{"Rejected"}</span>- {this.state.request.data}
                    </span>
                )
            }
            case DOC_REQUEST_STATUS.EXPIRED:{
                return (
                    <span>
                        <span>{"Document Expired"}</span>- {this.state.request.data}
                    </span>
                )
            }
            default:
                return;
        }        
    }

    handleSubmit = () => {
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            if (values.expiryDate.isBefore(moment())){
                message.error('Selected documet is expired');
                return;
            }
            const { reqId, isSiteReq } = this.props;
            const docId = values.document;
            const expiryDate = values.expiryDate;
            const docUploadId = this.state.request.id;
            const selectedDoc = (this.props.isSiteReq ? this.props.userDocuments : this.props.documents).find(doc=>doc.id===docId);
            message.loading("Submitting data", 0);
            await completeOrgDocRequest(reqId, docId, expiryDate, docUploadId, isSiteReq, selectedDoc.latest_version);
            message.destroy();
            message.success("Successfully completed the request");
            this.props.history.push(`/org/${this.state.request.org_id}`);
        });
    }

    handleNewDocumentModalClose = () => {
        this.setState({ isNewDocumentModalVisible: false });
    }

    handleNewDocumentModalSave = () => {
        this.setState({ isNewDocumentModalVisible: false });
        this.loadDocList();
    }

    openNewDocumentModal = () => {
        this.setState({ isNewDocumentModalVisible: true });
    }

    onDocumentSelect = (docId) => {
        
        this.props.form.setFieldsValue({ expiryDate: this.getExpiryDateOfDoc(docId)});
    }

    getExpiryDateOfDoc = (docId) =>{
        const doc = (this.props.isSiteReq?this.props.userDocuments:this.props.documents).find(doc => doc.id === docId);
        return moment(doc.expiry_date);
    }

    render() {
        const request = this.state.request;
        if (!request || (!this.props.isSiteReq && !this.props.documents) || (this.props.isSiteReq && !this.props.userDocuments)) {
            return <ConnectLoading />;
        }
        const { form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <div>
                <NewDocumentModal
                    visible={this.state.isNewDocumentModalVisible}
                    onCancel={this.handleNewDocumentModalClose}
                    onSave={this.handleNewDocumentModalSave}
                    isUserDoc={this.props.isSiteReq}
                />
                <Card>
                    <Form layout='horizontal'>
                        <FormItem
                            {...formItemLayout}
                            label={"Requirement Status"}
                        >
                            {this.renderRequirementStatus()}
                        </FormItem>

                        <FormItem
                            {...formItemLayout}
                            label={"Document Title"}
                        >
                            {request.title}
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Select Document"}
                        >
                            {
                                getFieldDecorator('document', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select a document'
                                        }
                                    ],
                                    initialValue:request.status!==0?request.document_id : undefined
                                })(

                                    <Select
                                        disabled = {request.status>=50}
                                        style={{ width: '50%' }}
                                        placeholder={"Select"}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        onChange={this.onDocumentSelect}
                                    >
                                        {
                                            (this.props.isSiteReq ? this.props.userDocuments : this.props.documents).map(d => (
                                                <Option key={d.id} value={d.id}>{d.title}</Option>)
                                            )
                                        }
                                    </Select>
                                )
                            }
                            {request.status<50 &&
                                <div style={{ width: '49%', float: "right" }}>
                                    {"Don't see your document in this list?"}
                                    <button onClick={() => this.openNewDocumentModal()} style={{ paddingTop: 0, outline: "none" }} className="btn btn-link">Add a new one</button>
                                </div>
                            }
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Expiry Date"}
                        >
                            {
                                getFieldDecorator('expiryDate', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select expiry date'
                                        }
                                    ],
                                    initialValue: request.status!==0 ? this.getExpiryDateOfDoc(this.state.request.document_id):undefined
                                })(
                                    <DatePicker placeholder={"Select date"} style={{ width: '50%' }} disabled={true} />
                                )
                            }
                        </FormItem>
                        {request.status<50 &&
                            <FormItem {...formTailLayout}>
                                <Button type="primary" onClick={() => this.handleSubmit()}>{"Submit"}</Button>
                            </FormItem>
                        }
                    </Form>
                </Card>
            </div>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        documents: state.getIn(["documentState", "documents"]),
        userDocuments: state.getIn(["documentState", "userDocuments"]),
        orgList: state.getIn(['loginUserState', 'orgList']),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadContractorDocs: bindActionCreators(loadContractorDocs, dispatch),
        loadUserDocs: bindActionCreators(loadUserDocs, dispatch),
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create()(DocumentUploadRequirementView)));