import React, { Component } from 'react';
import { Form, Modal, Select, Input, DatePicker, Upload, Button, Icon, message, Alert } from 'antd';
import { connect } from 'react-redux';
import { DOCUMENT_CATEGORIES } from '../Constants';
import { addDocument, addUserDocument, addInitalUserDocumentVersion, addInitialDocumentVersion } from '../actions/documentActions';
import moment from 'moment';
import _ from 'lodash';
import PDFViewer from '../../../components/PDFViewer';
import { isValidPdf } from '../../../utils/common-utils';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const validState = {
    status: 'success',
    message: null
}

const invalidUploadState = {
    status: 'error',
    message: 'Please upload a PDF'
}

const expiryDateHelper = "Expiry date is when the document must be renewed or revised";
const expiryDateInvalidHelper = "Please select an expiry date";

class NewDocumentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            uploadState: validState,
            expiryDateHelper: expiryDateHelper,
            docContent: null,
            pdfFile: null,
            pdfModalVisible: false,
            isCorruptedPDF: false
        }
    }

    resetFields = () => {
        this.setState({
            fileList: [],
            uploadState: validState,
            expiryDateHelper: expiryDateHelper,
            pdfFile: null,
            docContent: null,
            pdfModalVisible: false, 
            isCorruptedPDF: false
        });
    }

    handleCancel = () => {
        this.resetFields();
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form } = this.props;
        const { fileList } = this.state;
        this.setState({ expiryDateHelper: expiryDateHelper });
        this.setState({ uploadState: validState });
        form.validateFields(async(err, values) => {

            if (!values.expiryDate) {
                this.setState({ expiryDateHelper: expiryDateInvalidHelper })
            }

            if (fileList.length === 0) {
                this.setState({ uploadState: invalidUploadState });
                return;
            }

            if (err) {
                return;
            }
            message.loading('Uploading document',0)
            const data = {
                title: values.documentTitle,
                category: values.category,
                expiryDate: values.expiryDate.format('DD/MM/YYYY')
            }
            if(this.props.isUserDoc){
                const docId = await addUserDocument(this.props.userId, data, this.state.fileList[0]);
                await addInitalUserDocumentVersion(docId, data.expiryDate);
            }else{
                const docId = await addDocument(this.props.contractorId, data, this.state.fileList[0]);
                await addInitialDocumentVersion(docId, data.expiryDate);
            }
            message.destroy();
            message.success('Uploaded successfully');

            form.resetFields();
            this.resetFields();
            this.props.onSave();
        });
    }

    // decodes file into a binary format
    decodeBase64Data = async(base64) => {
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
            
        }
        
        // check validity of the pdf (whether it is currupted) 
        const status = await isValidPdf(bytes.buffer);
        if (!status) {
            this.setState({isCorruptedPDF: true});
            message.error('The selected pdf is corrupted. Please try another pdf.')
            return false;
        } else {
            this.setState({isCorruptedPDF: false, uploadState: validState});
        }
        
        return bytes.buffer;
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;        
        
        const pdfUploadSettings = {
            action: '/upload',
            accept: '.pdf',
            multiple: false,
            beforeUpload: (file) => {
                this.setState(({ fileList }) => ({
                    fileList: [...fileList, file],
                }));
    
                const reader = new FileReader();
                reader.onload = async(e) => {
                    const base64Data = e.target.result.replace('data:application/pdf;base64,', '');
                    let documentContent = await this.decodeBase64Data(base64Data);
                    if(!documentContent) {
                        let fileList = this.state.fileList;
                        _.remove(fileList, (i) => i.name === file.name);
                        this.setState({ fileList });
                        return false;
                    } else {
                        this.setState({docContent: documentContent})
                    }
                    
                };
                reader.readAsDataURL(file);
    
                return false; // Prevent upload
            },
            onRemove: (file) => {
                let fileList = this.state.fileList;
                _.remove(fileList, (i) => i.name === file.name);
                this.setState({ fileList, docContent: null });
            },
            fileList: this.state.fileList
        }
                
        return (
            <>
                <Modal
                    title={"Add New Document"}
                    visible={this.props.visible}
                    okText={"Save"}
                    cancelText={"Cancel"}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                >
                    <Form layout='horizontal'>
                        <FormItem
                            {...formItemLayout}
                            label={"Document Title"}
                        >
                            {
                                getFieldDecorator('documentTitle', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please type document title'
                                        }
                                    ]
                                })(
                                    <Input maxLength={200} placeholder={"Enter title"} />
                                )
                            }
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Category"}
                        >
                            {
                                getFieldDecorator('category', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select document category'
                                        }
                                    ]
                                })(
                                    <Select placeholder={"Select category"} >
                                        {
                                            DOCUMENT_CATEGORIES.map(i => (
                                                <Option key={i.id} value={i.name}>{i.name}</Option>)
                                            )
                                        }
                                    </Select>
                                )
                            }
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Expiry Date"}
                            help={this.state.expiryDateHelper}
                        >
                            {
                                getFieldDecorator('expiryDate', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please select document expiry date'
                                        }
                                    ]
                                })(
                                    <DatePicker style={{ width: '100%' }} disabledDate={date=>date.isBefore(moment())}/>
                                )
                            }
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={"Upload PDF"}
                            validateStatus={this.state.uploadState.status}
                            help={this.state.uploadState.message}
                        >
                            {
                                getFieldDecorator('uploadPDF', {
                                })(
                                    <Upload {...pdfUploadSettings}>
                                        <Button type="primary">
                                            <Icon type="upload" /> Click to Upload
                                        </Button>
                                    </Upload>
                                )
                            }
                        </FormItem>
                        {this.state.isCorruptedPDF && (<Alert message="The selected pdf is corrupted. Please try another pdf." type="error" closable={true} />)}
                        {this.state.docContent && (
                            <div>
                                <a onClick={()=>this.setState({pdfModalVisible:true})}>View PDF</a>
                            </div>
                        )}
                            </Form>
                        </Modal>
                        <Modal
                            title={"View PDF"}
                            visible={this.state.pdfModalVisible}
                            onCancel={()=>this.setState({pdfModalVisible:false})}
                            destroyOnClose={true}
                            width={1100}
                            className='view-pdf-modal'
                            footer={null}
                        >
                            <PDFViewer docContent={this.state.docContent} fileName='view-pdf' isPDFViewMaximized={this.state.pdfModalVisible} ></PDFViewer>
                        </Modal>
            </>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        contractorId: state.getIn(['loginUserState', 'contractorId']),
        userId: state.getIn(['loginUserState', 'userId'])
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewDocumentModal));