import React from 'react';
import {Modal, message} from 'antd';

export function openFile(fileUrl, loadInCurrentWindow){
    setTimeout(() => {
        const open = window.open(fileUrl, '_blank');
        if (open == null || typeof(open)=='undefined') {
            // If popups are not supported let user download the file in a modal window.
            message.info("Turn off your pop-up blocker!");
            Modal.info({
                title: 'Download File',
                content: (
                  <div>
                    <a href={fileUrl} target='_blank' rel="noopener noreferrer">Download</a>
                  </div>
                ),
                onOk() {},
            });
        }
    }, 200);

}