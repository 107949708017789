import React, { Component } from 'react';
import { Card, Tabs, Button } from 'antd'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMenu } from '../../../actions/commonActions';
import { setBreadcrumb } from '../../../actions/commonActions';
import ConnectLoading from '../../../components/ConnectLoading';

import { getUserDoc, getContractorDoc, getUserDocVersions, getContractorDocVersions} from '../actions/documentActions';
import DetailsTable from '../../../components/DetailsTable';
import { ListWidget } from '../../../components/ListWidget';
import { DocumentVersionListItem } from '../DocumentListItemBuilder';
import NewDocumentVersionModal from '../components/NewDocumentVersionModal';
import moment from 'moment';
import EditDocumentModal from '../components/EditDocumentModal';

const TabPane = Tabs.TabPane;

class DocumentDetailView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            document: undefined,
            versions: undefined
        }
        this.props.setSelectedMenu(['documents']);
    }

    componentDidMount = () =>{
        this.loadDocument();
        this.loadVersions();
    }
    
    loadDocument = async()=>{
        let document
        if(this.props.isUserDoc){
            document = await getUserDoc(this.props.docId);            
        }else{
            document = await getContractorDoc(this.props.docId);
        }
        this.setState({document});
    }
    
    loadVersions = async()=>{
        let versions
        if(this.props.isUserDoc){
            versions = await getUserDocVersions(this.props.docId);            
        }else{
            versions = await getContractorDocVersions(this.props.docId);
        }
        this.setState({versions});
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if(this.state.document !== prevState.document){
            this.updateBreadCrumb();
        }
    }

    updateBreadCrumb = () => {        
        this.props.setBreadcrumb(this.state.document.title, [
            { route: '/documents', label: 'Documents'},
            { route: '#', label: this.state.document.title},
        ]);
    }    

    getVersionsForListView = () => {
        if(!this.state.versions){
            return [];
        }
        let versionList = this.state.versions.map((version) => {
            let builder = new DocumentVersionListItem.Builder(version, this.props.isUserDoc);
            return builder.build();
        });
        return versionList;
    }

    handleEditDocumentModalSave = () => {
        this.setState({ isEditDetailsVisible: false });
        this.loadDocument();
    }

    renderDocDetailsView = () => {
        const { title, category, expiry_date, latest_version} = this.state.document;
        let fields = [
            { key: "Title", value: title },
            { key: "Category", value: category },
            { key: "Expiry Date", value: moment(expiry_date).format('DD/MM/YYYY') },
            { key: "Version", value: latest_version }
        ];
        return (
            <div className="pure-g">
                <EditDocumentModal
                    visible={this.state.isEditDetailsVisible}
                    onCancel={()=>this.setState({ isEditDetailsVisible: false })}
                    onSave={this.handleEditDocumentModalSave}
                    document={this.state.document}
                    isUserDoc={this.props.isUserDoc}
                />
                <div className="pure-u-1">
                    <div className="pure-g" style={{ padding: 15 }}>
                        <div className="pure-u-1">
                            <Button icon="edit" className='epar__tasks__action--button' onClick={() => this.setState({ isEditDetailsVisible: true })}>Edit Details</Button>
                        </div>
                    </div>
                    <div>
                        <div className="pure-u-1">
                            <DetailsTable dataSource={fields} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderVersionView = () =>{
        return(
            <div>
                <NewDocumentVersionModal
                    visible={this.state.isAddVersionVisible}
                    onCancel={()=>this.setState({isAddVersionVisible:false})}
                    onSave={()=>{
                        this.loadDocument();
                        this.loadVersions();
                        this.setState({isAddVersionVisible:false});
                    }}
                    docId={this.props.docId}
                    isUserDoc={this.props.isUserDoc}
                />
                <div className="pure-g">
                    <div className="pure-u-1">
                        <Button onClick={() => this.setState({ isAddVersionVisible: true })} className='epar__tasks__action--button'>
                            {"Add New Version"}
                        </Button>
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getVersionsForListView()} loading={this.state.versions===undefined} />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if(!this.state.document){
            return <ConnectLoading/>
        }
        return (
            <Card>
                <Tabs className='epar__details__view'>
                    <TabPane tab="Details" key="0">
                        {this.renderDocDetailsView()}
                    </TabPane>
                    <TabPane tab="Version Control" key="1">
                        {this.renderVersionView()}
                    </TabPane>
                </Tabs>
            </Card>
        );
    }
}


function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['loginUserState', 'orgList']),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetailView);