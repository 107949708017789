import React from 'react';
import { Steps, Button, message, Card } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { CourseContentView } from './CourseContentView';
import { CourseQuizForm } from './CourseQuizForm';
import { setBreadcrumb, setSelectedMenu } from '../../../actions/commonActions';
import { getOrgCourse, submitAnswers } from '../actions/courseActions';
import ConnectLoading from '../../../components/ConnectLoading';
import { DOC_REQUEST_STATUS } from '../../documents/actions/documentActions';

const Step = Steps.Step;
const STEP_CONTENT = 0;
const STEP_QUIZ = 1;
const STEP_FINISH = 2;

class CourseTaskView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            request: undefined,
            step: 0,
            activeQuestion: "0",
            answersSubmitted: false,
            results: undefined
        };
        this.loadRequest();
    }


    componentDidUpdate = async (prevProps, prevState) => {
        if (this.state.request !== prevState.request || this.props.orgList !== prevProps.orgList) {
            this.updateBreadCrumb();
        }
    }

    updateBreadCrumb = () => {
        if (this.state.request && this.state.request.org_id) {
            const org = this.props.orgList.find(org => org.org_id === this.state.request.org_id)
            if (org) {
                const orgName = org.org_name;
                this.props.setBreadcrumb("Online Training", [
                    { route: '#', label: 'Organisations' },
                    { route: `/org/${this.state.request.org_id}`, label: orgName },
                    { route: '', label: 'Online Training' },
                ]);
            }
        }
    }

    onFinish = () => {
        this.props.history.push(`/org/${this.state.request.org_id}`);
    }

    next = () => {
        const step = this.state.step + 1;
        this.setState({ step });
    }
    onNextQuestion = () => {
        const activeQuestion = `${parseInt(this.state.activeQuestion, 10) + 1}`;
        this.setState({ activeQuestion });
    }

    onPrevQuestion = () => {
        const activeQuestion = `${parseInt(this.state.activeQuestion, 10) - 1}`;
        this.setState({ activeQuestion });
    }

    renderButtons = () => {
        const { step } = this.state;
        return (
            <div className="steps-action">
                {step === STEP_CONTENT && <Button type="primary" onClick={() => this.next()}>Start Quiz</Button>}
                {step === STEP_QUIZ && <Button type="primary" onClick={() => this.submitAnswers()} disabled={this.state.answersSubmitted} >Submit Answers</Button>}
                {step === STEP_FINISH && <Button type="primary" onClick={this.onFinish} >Finish</Button>}
            </div>
        );
    };

    loadRequest = async () => {
        const request = await getOrgCourse(this.props.reqId, this.props.isSiteReq);
        this.setState({ request });
        this.props.setSelectedMenu([request.org_id]);
    }

    submitAnswers = () => {
        const form = this.form;
        form.validateFields(async (errors, values) => {
            if (errors) {
                this.setState({ activeQuestion: Object.keys(errors)[0] });
                return;
            }
            message.loading('Validating answers. Please wait..', 0);
            await this.setState({ answersSubmitted: true });
            const { org_id, course_id, id, course_req_id } = this.state.request;
            let results = await submitAnswers(org_id, course_id, values, id, course_req_id, this.props.isSiteReq);
            this.setState({ results, step: this.state.step + 1 });
            message.destroy();
        });
    }

    saveFormRef = (form) => {
        this.form = form;
    };

    getView = () => {
        switch (this.state.step) {
            case STEP_CONTENT:
                return (
                    <CourseContentView
                        contentUrl={this.state.request.contentUrl}
                    />
                );
            case STEP_QUIZ:
                return (
                    <CourseQuizForm
                        ref={this.saveFormRef}
                        questions={this.state.request.questions}
                        activeQuestion={this.state.activeQuestion}
                        prevQuestion={this.onPrevQuestion}
                        nextQuestion={this.onNextQuestion}
                        onActiveQuestionChange={activeQuestion => this.setState({ activeQuestion })}
                    />
                );
            case STEP_FINISH:
                return (
                    <div>
                        <span>You have successfully answered {this.state.results.correctAnswers} out of {this.state.results.totalQuestions}</span>
                        <br />
                        {this.state.results.competent && <span>Congratulations! You have been marked as Competent for this course.</span>}
                        {!this.state.results.competent && <span>To be marked as competent for this course you are required to get 100%. Your attempt resulted in {Math.floor(this.state.results.correctAnswers / this.state.results.totalQuestions * 100)}%.</span>}
                    </div>
                );
            default:
                break;

        }

    };

    render() {
        if (!this.state.request) {
            return <ConnectLoading />;
        }
        const { step } = this.state;
        return (
            <div>
                <Card>
                    <div className='epar__wizard--wrapper'>
                        <div className="pure-g">
                            <div className='pure-u-1-1'>
                                <Steps current={step} size='small'>
                                    <Step title={"Review"} />
                                    <Step title={"Quiz"} />
                                    <Step title={"Finish"} />
                                </Steps>
                            </div>
                        </div>
                        <div className="epar__wizard-content-wrapper" >
                            {this.getView()}
                        </div>
                        <div className="pure-g epar__group-wiz__nav-buttons__container__left">
                            {this.state.request.status!==DOC_REQUEST_STATUS.VERIFIED && this.renderButtons()}
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['loginUserState', 'orgList']),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseTaskView));