import { Map } from 'immutable';
import { USER_ACTIONS } from '../actions/actionTypes';
import * as SearchUtils from '../../../utils/search-utils';

function buildUserIndex(users) {
    return SearchUtils.buildIndex(users, 'user_id', ['first_name', 'last_name', 'email']);
};

const initialState = Map({
    users: undefined,
    userIndex: buildUserIndex([])
});

export default function userState(state = initialState, action) {
    switch (action.type) {
        case USER_ACTIONS.USER_LIST:
            return state.set('users', action.payload).set('userIndex',
            buildUserIndex(action.payload));
        default:
            return state
    }
}  