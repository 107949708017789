import { combineReducers } from 'redux-immutable';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import loginUserState from '../views/login/reducers/loginUserState';
import appConfigState from './appConfigState';
import breadcrumbState from './breadcrumbState';
import documentState from '../views/documents/reducer/documentState';
import orgState from '../views/organisations/reducer/orgState';
import userState from '../views/users/reducer/userState';

import { LOGIN_ACTIONS } from '../views/login/actions/actionTypes';


const appReducer = combineReducers({
  loginUserState,
  appConfigState,
  documentState,
  orgState,
  breadcrumbState,
  userState
});

const rootReducer = (state, action) => {
  if (action.type === LOGIN_ACTIONS.LOGOUT) {
    state = undefined
  }
  return appReducer(state, action)
}

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware // lets us dispatch() functions
  )
);

//export const dispatchStoreAction = (action, payload) => store.dispatch({type:action, payload:payload});
//export const getStoreVal = (stateName, key) => store.getState().getIn([stateName, key]);

export default store;
