import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import LoginPage from './views/login/containers/LoginPage';
import SignUpPage from './views/login/containers/SignUpPage';
import EmailVerifyPage from './views/login/containers/EmailVerifyPage';
import { continueSession } from './views/login/actions/loginActions';
import MainDashboardPage from './views/dashboard/containers/MainDashboardPage';
import ConnectLoading from './components/ConnectLoading';
import ForgotPasswordView from './views/login/containers/ForgotPasswordView';
import ResetPasswordFormView from './views/login/containers/ResetPasswordFormView';
import {PageView, initGA} from './components/Analytics';
import { CONFIG } from './config/app-config';

class App extends Component {
  constructor(props) {
    super(props);
    this.props.continueSession();
  }

  componentDidMount() {
    initGA(CONFIG.trackingId);
    PageView();
  }

  render() {
    if (!this.props.initialLoadCompleted) {
      return <ConnectLoading/>;
    }
    const isAuthenticated = this.props.isAuthenticated;
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          {!isAuthenticated && <Route exact path='/forgot_password' render={() => (<ForgotPasswordView/>)}/>}
          {!isAuthenticated && <Route exact path='/reset_password' render={() => (<ResetPasswordFormView/>)}/>}
          {isAuthenticated && <Route render={() => <MainDashboardPage />} />}
          {!isAuthenticated && <Route exact path='/login' render={() => <LoginPage />} />}
          {!isAuthenticated && <Route 
                                  path='/signup/:email/:token' 
                                  render={
                                    (route) => <SignUpPage 
                                                  email={route.match.params.email} 
                                                  token={route.match.params.token}/>
                                    }/>}
          {!isAuthenticated && <Route 
                                  path='/verify_email/:email/:code' 
                                  render={
                                    (route) => <EmailVerifyPage 
                                                  email={route.match.params.email} 
                                                  code={route.match.params.code}/>
                                    }/>}
          <Route render={() => isAuthenticated ? <Redirect to="/" /> : <Redirect from='/' to="/login" />} />
        </Switch>
      </BrowserRouter>
    );
  }
}
function mapStateToProps(state, props) {
  return {
    isAuthenticated: state.getIn(['loginUserState', 'isAuthenticated']),
    initialLoadCompleted: state.getIn(['loginUserState', 'initialLoadCompleted'])
  }
}

function mapDispatchToProps(dispatch) {
  return {
    continueSession: bindActionCreators(continueSession, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
