import ReactGA from 'react-ga';

//initialize tracking id for the project//
export const initGA = (trackingID) => {           
    ReactGA.initialize(trackingID); 
 }

 //Get url for tracking//
export const PageView = () => {  
    ReactGA.pageview(window.location.pathname + window.location.search); 
}

//Event tracking//
export const Event = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
};