// @flow
import { isTablet as isTabletBrowser, isMobile as isMobileBrowser } from "react-device-detect";
import { Storage } from 'aws-amplify';

/**
 * Detect mobile
 */
export function isMobile() {
  return isMobileBrowser;
}

export function isTablet() {
  return isTabletBrowser;
}

export function uploadFile(file: any, fileName: string, uploadBucket: string, folderName): * {
  return new Promise((resolve, reject) => {
      console.log(`Uploading ${fileName}`);
      // AWS amplify otherise uploads to public, private, protected folders in the bucket. Deafult is public. 
      // We need to customise so the file is uploaded to the folder with the name we specify.
      const customPrefix = {
          public: folderName + '/'
      };
      Storage.configure({ bucket: uploadBucket });
      Storage.put(fileName, file, { customPrefix: customPrefix })
          .then((result) => {
              console.log(result);
              resolve(fileName);
          })
          .catch((err) => {
              reject(fileName);
              console.log(err);
          });
  });
}

export function getFileLink(fileName: string, bucket: string, folderName): * {
  return new Promise((resolve, reject) => {
      const customPrefix = {
          public: folderName + '/'
      };
      Storage.configure({ bucket: bucket });
      Storage.get(fileName, { customPrefix: customPrefix })
          .then((result) => {
              resolve(result);
          })
          .catch((err) => {
              reject(err);
              console.log(err);
          });
  });
}

export function isValidPdf (file) {
  return new Promise((resolve, reject) => {
      try {
          const reader = new FileReader();

          // If the file is an ArrayBuffer or Uint8Array, convert it to a Blob
          if (file instanceof ArrayBuffer || file instanceof Uint8Array) {
              file = new Blob([file], { type: 'application/pdf' });
          }

          reader.onload = (event) => {
              const uint8Array = new Uint8Array(event.target.result);
              const header = String.fromCharCode(...uint8Array.slice(0, 5));                    

              if (header === '%PDF-') {
                  resolve(true);
              } else {
                  resolve(false);
              }
          };

          reader.onerror = (error) => {
              reject(error);
          }

          reader.readAsArrayBuffer(file);
      } catch (e) {
          reject(e);
      }
  });
}

