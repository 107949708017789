import { getApi, ContractorApi } from "../../../utils/api-utils";

export async function getDashboardData() {
    try {
        const response = await getApi(ContractorApi.COMMON_API, `/dashboard`);
        return response.data;
    } catch (e) {
        console.log(e);
    }
}
