import React, { Component } from 'react';
import { Form, Modal, DatePicker, Upload, Button, Icon, message, notification } from 'antd';
import { connect } from 'react-redux';
import { addDocumentVersion, addUserDocumentVersion } from '../actions/documentActions';
import moment from 'moment';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const validState = {
    status: 'success',
    message: null
}

const invalidUploadState = {
    status: 'error',
    message: 'Please upload a PDF'
}

const expiryDateHelper = "Expiry date is when the document must be renewed or revised";
const expiryDateInvalidHelper = "Please select an expiry date";

class NewDocumentVersionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            uploadState: validState,
            expiryDateHelper: expiryDateHelper
        }
    }

    resetFields = () => {
        this.setState({
            fileList: [],
            uploadState: validState,
            expiryDateHelper: expiryDateHelper
        });
    }

    handleCancel = () => {
        this.resetFields();
        this.props.form.resetFields();
        this.props.onCancel();
    }

    requirementUpdateMessage = () => {
        notification['warning']({
          message: 'Update Requirements.',
          description:
            "Please assign the new version to organisations that have requested this document. Requirements linked to this document will now show as 'Missing' and require you to assign the updated version.",
            duration: 40,
        });
      };

    handleOk = () => {
        const { form } = this.props;
        const { fileList } = this.state;
        this.setState({ expiryDateHelper: expiryDateHelper });
        this.setState({ uploadState: validState });
        form.validateFields(async(err, values) => {

            if (!values.expiryDate) {
                this.setState({ expiryDateHelper: expiryDateInvalidHelper })
            }

            if (fileList.length === 0) {
                this.setState({ uploadState: invalidUploadState });
                return;
            }

            if (err) {
                return;
            }
            message.loading('Uploading document',0)
            const expiryDate = values.expiryDate.format('DD/MM/YYYY');
            if(this.props.isUserDoc){
                await addUserDocumentVersion(this.props.userId, this.props.docId, expiryDate, this.state.fileList[0]);
            }else{
                await addDocumentVersion(this.props.contractorId, this.props.docId, expiryDate, this.state.fileList[0]);
            }
            message.destroy();
            message.success('Uploaded successfully');
            this.requirementUpdateMessage();

            form.resetFields();
            this.resetFields(); //resetting state
            this.props.onSave();
        });
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        const pdfUploadSettings = {
            action: '/upload',
            accept: '.pdf',
            beforeUpload: (file) => {
                this.setState({ fileList: [file] });
                return false;
            },
            fileList: this.state.fileList
        }

        return (
            <Modal
                title={"Add New Version"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
            >
                <Form layout='horizontal'>                    
                    <FormItem
                        {...formItemLayout}
                        label={"Expiry Date"}
                        help={this.state.expiryDateHelper}
                    >
                        {
                            getFieldDecorator('expiryDate', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select document expiry date'
                                    }
                                ]
                            })(
                                <DatePicker style={{ width: '100%' }} disabledDate={date=>date.isBefore(moment())}/>
                            )
                        }
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={"Upload PDF"}
                        validateStatus={this.state.uploadState.status}
                        help={this.state.uploadState.message}
                    >
                        {
                            getFieldDecorator('uploadPDF', {
                            })(
                                <Upload {...pdfUploadSettings}>
                                    <Button type="primary">
                                        <Icon type="upload" /> Click to Upload
                                    </Button>
                                </Upload>
                            )
                        }
                    </FormItem>

                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        contractorId: state.getIn(['loginUserState', 'contractorId']),
        userId: state.getIn(['loginUserState', 'userId'])
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewDocumentVersionModal));