import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ListWidget } from '../../../components/ListWidget';
import { getGeneralRequirements } from '../actions/orgActions';
import { RequestListItem } from '../RequestListItemBuilder';
import Search from '../../../components/Search';
import * as SearchUtils from '../../../utils/search-utils';


class GeneralRequirementsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docRequests: [],
        }
    }

    componentDidMount = async () => {
        if (this.props.orgId) {
            this.loadDocRequests(this.props.orgId);
        }
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.orgId && prevProps.orgId !== this.props.orgId) {
            this.loadDocRequests(this.props.orgId);
        }
    }

    loadDocRequests = async (orgId) => {
        await this.props.getGeneralRequirements(orgId);
    }

    getRequestsForListView = () => {
        let reqList = this.filterRequirements().map((req, index) => {
            let builder = new RequestListItem.Builder(req, index);
            return builder.build();
        });
        return reqList;
    }

    filterRequirements = () => {
        return SearchUtils.filter(this.props.generalRequirements.get(this.props.orgId) ? this.props.generalRequirements.get(this.props.orgId).requirements : [], "id", this.state.searchMatches);
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.generalRequirements.get(this.props.orgId) ? this.props.generalRequirements.get(this.props.orgId).indexes : [], phrase);
        this.setState({ searchMatches: matches });
    }

    render() {
        return (
            <div>
                <div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            options={[]}
                        />
                    </div>
                    <div className="pure-u-1 pure-u-md-2-3">
                    </div>
                </div>
                <div className="pure-g" style={{ marginTop: 10 }}>
                    <div className="pure-u-1">
                        <ListWidget data={this.getRequestsForListView()} loading={!this.props.generalRequirements.has(this.props.orgId)} />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        generalRequirements: state.getIn(['orgState', 'generalRequirements']),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getGeneralRequirements: bindActionCreators(getGeneralRequirements, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(GeneralRequirementsView);