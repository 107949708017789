// @flow
import { Map } from 'immutable';
import { LOGIN_ACTIONS } from './../actions/actionTypes';

const initialState: any = Map({
  initialLoadCompleted: false,
  isAuthenticated: false,
  username: undefined,
  userId: undefined,
  name: undefined,
  contractorId: undefined,
  orgList: [],
  requireNewPassword: false,
  isAdmin: false

});

export default function loginUserState(state: any = initialState, action: any) {
  switch (action.type) {
    case LOGIN_ACTIONS.SUCCESS:
      const userData = action.payload;
      return state.merge({
        'userId': userData.user_id,
        'name': userData.first_name,
        'contractorId': userData.contractor_id,
        'isAdmin': userData.isAdmin==='1',
        'isAuthenticated': true,
        'initialLoadCompleted': true
      });
    case LOGIN_ACTIONS.NO_SESSION:
      return state.merge({
        'initialLoadCompleted': true
      });
    case LOGIN_ACTIONS.ORG_LIST:
      return state.merge({
        'orgList': action.payload
      });
    case LOGIN_ACTIONS.NEW_PASSWORD_REQUIRED:
      const newState = state.merge(action.payload);
      return newState.merge({
        'requireNewPassword': true
      })
    case LOGIN_ACTIONS.NEW_PASSWORD_SET:
      return state.set('requireNewPassword', false);
    default:
      return state
  }
}
