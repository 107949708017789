import { getApi, ContractorApi } from "../../../utils/api-utils";
import { ORG_ACTIONS } from '../actions/actionTypes';

export function getGeneralRequirements(orgId) {
    return async function (dispatch, getState) {
        try {
            const data = await getApi(ContractorApi.DOCUMENT_API, `/org_general_requests`, { queryStringParameters: { orgId } });
            const requirements = data.requestList;
            await dispatch({
                type: ORG_ACTIONS.GENERAL_REQUIRENMENTS,
                payload: { orgId, requirements }
            });
        } catch (e) {
            console.log(e);
        }
    }
}


export function getSiteRequirements(orgId) {
    return async function (dispatch, getState) {
        try {
            const data = await getApi(ContractorApi.REQUIREMENT_API, `/site_requirements`, { queryStringParameters: { orgId } });
            const requirements = data.requestList;
            await dispatch({
                type: ORG_ACTIONS.SITE_REQUIRENMENTS,
                payload: { orgId, requirements }
            });
        } catch (e) {
            console.log(e);
        }
    }
}