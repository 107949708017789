// @flow

import { APP_ACTIONS, BREADCRUMB_ACTIONS } from './actionTypes';

export function toggleSidePanel(toggle) {
  return function (dispatch, getState) {
    dispatch({
      type: APP_ACTIONS.TOGGLE_SIDEPANEL,
      payload: toggle
    })
  }
}

export function setSelectedMenu(menus) {
  return function (dispatch, getState) {
    dispatch({
      type: APP_ACTIONS.SET_SELECTED_MENU,
      payload: menus
    })
  }
}

export function setBreadcrumb(title, path) {
  return function (dispatch, getState) {
    dispatch({
      type: BREADCRUMB_ACTIONS.SET_BREADCRUMB,
      payload: {
        title: title,
        path: path
      }
    })
  }
}