import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Form, Input, Button, Row, Col, message } from 'antd';

import { signUp, completeUserProfile } from '../actions/loginActions';

const FormItem = Form.Item;

class SignUpFormView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      signupInProgress: false
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        message.loading('Signing up. Please wait', 0);
        this.setState({signupInProgress:true});
        let errMsg;
        if(this.props.tempPassword) {
          await completeUserProfile(values.firstName, values.lastName, this.props.email,  values.password, this.props.tempPassword, this.props.userId, this.props.token);
        }
        else{
          errMsg = await signUp(values.firstName, values.lastName, this.props.email,  values.password, this.props.token);
        }
        message.destroy();
        if (errMsg) {
          message.error(errMsg);
          this.setState({signupInProgress:false});
        }else{          
          message.success(`Signup is complete. Please login to continue.`, 10);
          this.props.history.push('/login');
        }
      }
    });
  }
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };    

    return (
      <Layout>

        <div className='pure-g epar__login__form-header'>
          <div className='pure-u-1-1'>
            <div className='pure-g'>
              <div className='pure-u-1'>
                <img src='/assets/logo.png' className="epar__login__form--logo" alt='' />
              </div>
            </div>
            <div className='pure-g epar__login__form--label'>
              <div className='pure-u-1 epar__content--center'>
                Create your new account
              </div>
            </div>
          </div>
        </div>

        <Form onSubmit={this.handleSubmit} className="epar__login__form">
          <FormItem
            {...formItemLayout}
            label='First name'
          >
            {getFieldDecorator('firstName', {
              rules: [{ required: true, message: 'Please input your first name!' }],
            })(
              <Input placeholder='Enter first name'/>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label='Last name'
          >
            {getFieldDecorator('lastName', {
              rules: [{ required: true, message: 'Please input your last name!' }],
            })(
              <Input placeholder='Enter last name'/>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Email"
            extra="Your email address will be your new username."
          >
            {getFieldDecorator('email', {
              rules: [{
                type: 'email', message: 'The input is not valid E-mail!',
              }, {
                required: true, message: 'Please input your E-mail!',
              }],
              initialValue: this.props.email
            })(
              <Input placeholder='Enter email address' disabled={true}/>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Password"
            extra="For security reasons your password must be at least 8 characters, include a capital letter, and a number."
          >
            {getFieldDecorator('password', {
              rules: [{
                required: true, message: 'Please input your password!',
              }, {
                validator: this.validateToNextPassword,
              }],
            })(
              <Input type="password" placeholder='Enter password'/>
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label="Confirm Password"
          >
            {getFieldDecorator('confirmPassword', {
              rules: [{
                required: true, message: 'Please confirm your password!',
              }, {
                validator: this.compareToFirstPassword,
              }],
            })(
              <Input type="password" onBlur={this.handleConfirmBlur} placeholder='Re-type password'/>
            )}
          </FormItem>
          <FormItem className="epar__content--center">
            <Row justify='center' type='flex'>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="epar__login-form__submit--button"
                  disabled={this.state.signupInProgress}>
                  Create an account
                </Button>
              </Col>
            </Row>
          </FormItem>
        </Form>
      </Layout>
    )
  }
}

export default withRouter(Form.create()(SignUpFormView));
