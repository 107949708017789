import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, Tabs } from 'antd';

import { setSelectedMenu } from '../../../actions/commonActions';
import { setBreadcrumb } from '../../../actions/commonActions';
import GeneralRequirementsView from './GeneralRequirementsView';
import SiteRequirementsView from './SiteRequirementsView';

const TabPane = Tabs.TabPane;

class OrganisationsHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docRequests: [],
        }
        this.props.setSelectedMenu([props.orgId]);
    }

    componentDidMount = async () => {
        this.updateBreadCrumb();
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.orgId && prevProps.orgId !== this.props.orgId) {
            this.props.setSelectedMenu([this.props.orgId]);
        }
        if (this.props.orgId !== prevProps.orgId || this.props.orgList !== prevProps.orgList) {
            this.updateBreadCrumb();
        }
    }

    updateBreadCrumb = () => {
        if (this.props.orgId) {
            const org = this.props.orgList.find(org => org.org_id === this.props.orgId)
            if (org) {
                const orgName = org.org_name;
                this.props.setBreadcrumb(orgName, [
                    { route: '#', label: 'Organisations' },
                    { route: '', label: orgName },
                ]);
            }
        }
    }


    render() {
        if (this.props.isAdmin) {
            return (
                <Card>
                    <Tabs className='epar__details__view'>
                        <TabPane tab="General Requirements" key="0">
                            <GeneralRequirementsView orgId={this.props.orgId} />
                        </TabPane>
                        <TabPane tab="Individual Requirements" key="1">
                            <SiteRequirementsView orgId={this.props.orgId} />
                        </TabPane>
                    </Tabs>
                </Card>
            );
        }
        else {
            return (
                <SiteRequirementsView orgId={this.props.orgId} />
            );
        }
    }
}

function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['loginUserState', 'orgList']),
        isAdmin: state.getIn(['loginUserState', 'isAdmin']),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsHome);