import React, { Component } from 'react';
import { Layout, Row, Col, message } from 'antd';
import { withRouter } from 'react-router-dom';

import SignUpFormView from './SignUpFormView';
import { isValidToken } from '../actions/loginActions';
import ConnectLoading from '../../../components/ConnectLoading';

const { Content } = Layout;

class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tempPassword: null,
      userId: null
    }
  }

  componentDidMount = async() =>{
    const result = await isValidToken(this.props.email, this.props.token);
    if(!result || !result.validToken){
      message.error('Error: Invalid or used token', 10);
      this.props.history.push('/');
    }else{
      if(result.tempPass){
        this.setState({tempPassword: result.tempPass, userId: result.userId});
      }
      this.setState({isLoading:false});
    }
  };


  render() {
    if(this.state.isLoading){
      return <ConnectLoading/>
    }
    return (
      <Layout style={{ minHeight: '100vh', padding: '20px' }}>
        <Content style={{ minHeight: '100vh' }}>
          <Row style={{ minHeight: '100vh' }} className='epar__login__container' type="flex" justify="space-around" align="middle">
            <Col xs={20} sm={20} md={12} lg={9} xl={9}>
              <div className='epar__login__form__container'>
                <SignUpFormView t={this.props.t} email={this.props.email} token={this.props.token} tempPassword = {this.state.tempPassword} userId= {this.state.userId}/>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

export default withRouter(SignUpPage);