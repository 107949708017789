// @flow
/**
 * Contains API helpers.
 */
import { API, Auth } from 'aws-amplify';

export const ContractorApi = {
  ADMIN_API: 'adminAPI',
  COMMON_API: 'commonAPI',
  COURSE_API: 'courseAPI',
  DOCUMENT_API: 'documentAPI',
  REQUIREMENT_API: 'requirementAPI',
  USER_API: 'userAPI',
}

// --------- GENERIC API ------------------
export async function getApi(api: string, path: string, params: any) {
  let options = (params) ? params : {};
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  return API.get(api, path, options);
}

export async function putApi(api: string, path: string, body: any) {
  let options = { body };
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  return API.put(api, path, options);
}

export async function postApi(api: string, path: string, body: any) {
  let options = { body };
  await Auth.currentSession().then((session) => {
    let jwtToken = session.idToken.jwtToken;
    options = {
      ...options,
      headers: {
        Authorization: jwtToken
      }
    }
  });
  try{
    const result = await API.post(api, path, options);
    return result;
  }catch(err){
    if(err.response && err.response.data && err.response.data.error){
      console.error('ERROR:',err.response.data.error);
      throw err.response.data.error;
    }else{
      console.error('ERROR:',err);
      throw err;
    }
  }
}
