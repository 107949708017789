import { Map } from 'immutable';
import { DOCUMENT_ACTIONS } from '../actions/actionTypes';
import * as SearchUtils from '../../../utils/search-utils';

function buildDocumentIndex(docs) {
    return SearchUtils.buildIndex(docs, "id", ["title"]);
};

const initialState = Map({
    documents: undefined,
    documentIndex: buildDocumentIndex([]),
    userDocuments: undefined,
    userDocumentIndex: buildDocumentIndex([])
});

export default function documentState(state = initialState, action) {
    switch (action.type) {
        case DOCUMENT_ACTIONS.DOCUMENTS_LIST:
            return state.set('documents', action.payload).set('documentIndex',
                buildDocumentIndex(action.payload));
        case DOCUMENT_ACTIONS.USER_DOCUMENTS_LIST:
            return state.set('userDocuments', action.payload).set('userDocumentIndex',
                buildDocumentIndex(action.payload));
        default:
            return state
    }
}  