import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip, LabelList, Cell } from 'recharts';
import { Card } from 'antd';
import { withRouter } from 'react-router-dom';

import { getDashboardData } from '../actions/dashboardActions';
import ConnectLoading from '../../../components/ConnectLoading';


class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashBoardData: undefined
    }
    this.loadDashboardData();
  }

  componentDidUpdate = () => {
    const { orgList, history } = this.props;
    if(orgList.length < 1 ){
      history.push('/documents');
    }
  }

  loadDashboardData = async () => {
    const data = await getDashboardData();
    let dashBoardData = new Map();

    data.map(item => {
      let orgData = dashBoardData.has(item.org_id) ? dashBoardData.get(item.org_id) : { complete: 0, incomplete: 0 };
      item.status === 100 ? orgData.complete += item.count : orgData.incomplete += item.count
      dashBoardData.set(item.org_id, orgData);
      return null;
    })
    this.setState({ dashBoardData });
  }

  getOrgDashBoard = (org) => {
    let orgData = this.state.dashBoardData.has(org.org_id) ? this.state.dashBoardData.get(org.org_id) : { complete: 0, incomplete: 0 };
    let data = [];
    data.push({ name: 'Complete', value: orgData.complete, color: 'green' });
    data.push({ name: 'Incomplete', value: orgData.incomplete, color: 'red' });

    const total = data.reduce((total, d) => {
      return total + d.value;
    }, 0);

    return (
      <div className='pure-u-1 pure-u-sm-1-2 pure-u-lg-1-3 epar__dashboard--card' key={org.org_id}>
        <Card title={org.org_name}>
          <div className='epar__chart__container'>
            <Fragment>
              <ResponsiveContainer>
                <PieChart width={800} height={400}>
                  <Pie isAnimationActive={false} dataKey='value' nameKey='name' data={data} innerRadius={80} outerRadius={120}>
                    {
                      data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))
                    }
                    <LabelList dataKey="value" position="inside" stroke="black" content={content => this.renderLabel(content.value, total)} />
                  </Pie>
                  <Legend />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Fragment>
          </div>
        </Card>
      </div>

    )
  }

  renderLabel = (value, total) => {
    return `${(value * 100.0 / total).toFixed(0)}%`;
  };

  render() {
    if (!this.props.orgList || !this.state.dashBoardData) {
      return <ConnectLoading />
    }
    return (
      <div className='pure-g'>
        <div className='pure-u-1'>
          <div className='epar__dashboard--container'>
            <div className='pure-g'>
              <Fragment>
                {this.props.orgList.map(org=>this.getOrgDashBoard(org))}
              </Fragment>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    orgList: state.getIn(['loginUserState', 'orgList'])
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDashboard));
