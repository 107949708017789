
import { API, Auth } from 'aws-amplify';
import { LOGIN_ACTIONS } from '../actions/actionTypes';
import { ContractorApi, getApi } from '../../../utils/api-utils';
import { message } from 'antd';

export function authenticate(username, password, newPassword) {
  return async function (dispatch, getState) {
    try {
      const user = await Auth.signIn(username, password);      
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // Invoke new password required workflow
        if ((newPassword) && newPassword !== '') { // User has already supplied the new password
          await Auth.completeNewPassword(user, newPassword);
          const userData = JSON.parse(user.signInUserSession.idToken.payload.userData);
          dispatch({
            type: LOGIN_ACTIONS.SUCCESS,
            payload: userData
          });
          await loadOrgList(dispatch);
          dispatch({
            type: LOGIN_ACTIONS.NEW_PASSWORD_SET,
            payload: null
          });
        }
        else {
          dispatch({
            type: LOGIN_ACTIONS.NEW_PASSWORD_REQUIRED,
            payload: {
              username: username,
              password: ''
            }
          });
        }
      }
      else {
        const userData = JSON.parse(user.signInUserSession.idToken.payload.userData);
        await dispatch({
          type: LOGIN_ACTIONS.SUCCESS,
          payload: userData
        });
        await loadOrgList(dispatch);
      }
    } catch (err) {
      console.log(err);
      if (err.message) {
        return err.message
      }
      return 'Error occured, please try again';
    }
  }
}

export function continueSession() {
  return async function (dispatch, getState) {
    try {
      const session = await Auth.currentSession();      
      const userData = JSON.parse(session.idToken.payload.userData);
      await dispatch({
        type: LOGIN_ACTIONS.SUCCESS,
        payload: userData
      });
      await loadOrgList(dispatch);
    } catch (err) {
      console.log(err);
      await dispatch({
        type: LOGIN_ACTIONS.NO_SESSION,
        payload: undefined
      });
    }
  }
}

export function logout() {
  return async function (dispatch, getState) {
    try {
      await Auth.signOut();
      await dispatch({
        type: LOGIN_ACTIONS.LOGOUT,
        payload: undefined
      });
      await dispatch({
        type: LOGIN_ACTIONS.NO_SESSION,
        payload: undefined
      });
    } catch (err) {
      console.log(err);
    }
  }
}

export async function signUp(firstName, lastName, email, password, token) {
  const results = await API.post(ContractorApi.USER_API, '/signup', { body: { firstName, lastName, email, password, token } });
  if (results.err && results.err.message) {
    return results.err.message;
  }
}

export async function completeUserProfile(firstName, lastName, email, newPassword, password, userId, token) {
  await completePassword(userId, password, newPassword).then(async result=>{
    if(result) {
      await updateContractor(firstName, lastName, email, userId, token);
    }
    else{
      message.error("Error updating user");
    }
  });
}

const completePassword = async(username, password, newPassword) => {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, password).then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED'){
          // Invoke new password required workflow
          if ((newPassword) && newPassword !== ''){ // User has already supplied the new password
            Auth.completeNewPassword(user, newPassword).then(async data => {
                await Auth.signOut()
                resolve(true);
            })
            .catch((err) => {
                console.log(err);
                reject(err);
            });
          }
        } 
    })
    .catch(err => {
      console.log(err);
      reject(err);
    });
  });
}

const updateContractor = async(firstName, lastName, email, userId, token) => {
  const results = await API.put(ContractorApi.USER_API, '/contractor_update', { body: { firstName, lastName, email, token, userId } });
  if (results.err && results.err.message) {
    return results.err.message;
  }
}

export async function isValidToken(email, token) {
  const result = await API.get(ContractorApi.USER_API, '/valid_token', { queryStringParameters: { email, token } });
  return result;
}


async function loadOrgList(dispatch) {
  try {
    const result = await getApi(ContractorApi.USER_API, '/org_list');
    const orgList = result.orgList;
    await dispatch({
      type: LOGIN_ACTIONS.ORG_LIST,
      payload: orgList
    })
  } catch (err) {
    console.log(err);
  }
}

export async function verifyEmail(email, code) {
  const result = await API.post(ContractorApi.USER_API, `/verify_email/${email}/${code}`);
  return result;
}