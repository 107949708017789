import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserManagement from './containers/UserManagement';
import { setSelectedMenu } from '../../actions/commonActions';
import UserView from './containers/UserView';

class UsersDashboad extends Component {
    constructor(props){
        super(props);
        this.props.setSelectedMenu(['users']);
    }

    getView = (route) => {
        switch (route.match.params.page) {
            case 'user':
                return <UserView userId={route.match.params.param}/>
            default:
                return (<UserManagement />);
        }
    }

    render() {
        if(!this.props.isAdmin){
            this.props.history.push('/');
        }
        return (
            <Switch>
                <Route path={'/users/:page/:param'} render={this.getView} />
                <Route path={'/users'} render={this.getView} />
                <Redirect to='/users' />
            </Switch>
        )
    }

}

function mapStateToProps(state, props) {
    return {
        isAdmin: state.getIn(['loginUserState', 'isAdmin']),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch)
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersDashboad));