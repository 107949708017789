export const CONFIG = {
  cognitoUserPoolId: 'us-west-2_VTc9GKx1u',
  userPoolWebClientId: '1c76oflphvsr2bgpif67alcuem',
  cognitoIdentityPoolId: 'us-west-2:e619f14a-56b5-4c1d-8539-ebf923f0c406',
  awsRegion: 'us-west-2',
  documentsUploadsBucket: 'contractor-documents-prod',
  userUploadsBucket: 'contractor-userdata-prod',

  baseApiUrl: 'https://www.api.contractor.eparconnectserver.com/prod-',
  apiStage: 'prod',
  trackingId:"UA-164108890-3",
}

