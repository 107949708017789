import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Checkbox, Alert } from 'antd';

import { ListWidget } from '../../../components/ListWidget';
import { getSiteRequirements } from '../actions/orgActions';
import { SiteRequirementListItem } from '../RequestListItemBuilder';
import Search from '../../../components/Search';
import * as SearchUtils from '../../../utils/search-utils';
import { getUserInfo } from '../../users/actions/userActions';
import { Link } from 'react-router-dom';
import ConnectLoading from '../../../components/ConnectLoading';


class SiteRequirementsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docRequests: [],
            onlyMyTasks:false,
            OrgList: undefined,
        }
    }

    componentDidMount = async () => {
        if (this.props.orgId) {
            this.loadRequirements(this.props.orgId);
        }
        await this.getUserOrgs();
        this.setState({isLoading:false});
    }

    getUserOrgs = async () => {
        const userInfo=await getUserInfo(this.props.userId);
        if(userInfo){
            this.setState({OrgList : userInfo.organisations});
        }
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.orgId && prevProps.orgId !== this.props.orgId) {
            this.loadRequirements(this.props.orgId);
        }
    }

    loadRequirements = async (orgId) => {
        await this.props.getSiteRequirements(orgId);
    }

    getRequirementsForListView = () => {
        let reqList = this.filterRequirements().map((req, index) => {
            let builder = new SiteRequirementListItem.Builder(req, index);
            return builder.build();
        });
        return reqList;
    }

    filterRequirements = () => {
        return SearchUtils.filter(this.getRequirementList() , "id", this.state.searchMatches);
    }

    onSearch = (phrase) => {
        let matches = SearchUtils.search(this.props.siteRequirements.get(this.props.orgId) ? this.props.siteRequirements.get(this.props.orgId).indexes : [], phrase);
        this.setState({ searchMatches: matches });
    }

    onMyTasksChange = (e) => {
        this.setState({
            onlyMyTasks: e.target.checked,
        });
    }

    getRequirementList = () => {
        const reqList = this.props.siteRequirements.get(this.props.orgId) ? this.props.siteRequirements.get(this.props.orgId).requirements : [];
        return this.state.onlyMyTasks ? reqList.filter(req=>req.user_id===this.props.userId) : reqList;
    }

    renderListWidget = () => {
        if (this.state.OrgList.length === 0) {
            return <Alert
                message="Warning"
                description={
                    <div>
                        You are not assigned to any organisation yet. Please assign yourself to an organisation by <Link to={`/users/user/${this.props.userId}`} >editing the user details</Link>.
                    </div>
                }
                type="warning"
                showIcon />
        } else {
            return (
                <div><div className="pure-g">
                    <div className="pure-u-1-3">
                        <Search
                            searchText={"Search"}
                            handleSearch={(value) => this.onSearch(value)}
                            options={[]}
                        />
                    </div>
                    <div className="pure-u-1 pure-u-md-2-3" >
                        {this.props.isAdmin && (<Checkbox onChange={this.onMyTasksChange} style={{ float: 'right' }}>Only My Tasks</Checkbox>)}
                    </div>
                </div>
                    <div className="pure-g" style={{ marginTop: 10 }}>
                        <div className="pure-u-1">
                            <ListWidget data={this.getRequirementsForListView()} loading={!this.props.siteRequirements.has(this.props.orgId)} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        if(!this.state.OrgList){
            return <ConnectLoading/>;
        }

        return (
            <div>
                {this.renderListWidget()}
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        siteRequirements: state.getIn(['orgState', 'siteRequirements']),
        isAdmin: state.getIn(['loginUserState', 'isAdmin']),
        userId: state.getIn(['loginUserState', 'userId']),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSiteRequirements: bindActionCreators(getSiteRequirements, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SiteRequirementsView);