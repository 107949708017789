import React, { Component } from 'react';
import { Form, Modal, Select, Input, message } from 'antd';
import { connect } from 'react-redux';
import { DOCUMENT_CATEGORIES } from '../Constants';
import { updateDocumentDetails, updateUserDocumentDetails } from '../actions/documentActions';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class EditDocumentModal extends Component {
    handleCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form } = this.props;
        form.validateFields(async(err, values) => {
            if (err) {
                return;
            }
            message.loading('Saving changes...', 0);
            const data = {
                title: values.documentTitle,
                category: values.category
            }
            if(this.props.isUserDoc){
                await updateUserDocumentDetails(this.props.document.id, data);
            }else{
                await updateDocumentDetails(this.props.document.id, data);
            }
            message.destroy();
            message.success('Document details updated successfully');

            form.resetFields();
            this.props.onSave();
        });
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Modal
                title={"Edit Document Details"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
            >
                <Form layout='horizontal'>
                    <FormItem
                        {...formItemLayout}
                        label={"Document Title"}
                    >
                        {
                            getFieldDecorator('documentTitle', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please type document title'
                                    }
                                ],
                                initialValue:this.props.document.title
                            })(
                                <Input maxLength={200} placeholder={"Enter title"} />
                            )
                        }
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={"Category"}
                    >
                        {
                            getFieldDecorator('category', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select document category'
                                    }
                                ],
                                initialValue:this.props.document.category
                            })(
                                <Select placeholder={"Select category"} >
                                    {
                                        DOCUMENT_CATEGORIES.map(i => (
                                            <Option key={i.id} value={i.name}>{i.name}</Option>)
                                        )
                                    }
                                </Select>
                            )
                        }
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        contractorId: state.getIn(['loginUserState', 'contractorId']),
        userId: state.getIn(['loginUserState', 'userId'])
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(EditDocumentModal));