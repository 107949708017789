import React from 'react';
import { ListItem } from '../../components/ListItemBuilder';
import { Row, Checkbox } from 'antd';

export class UserListItem {
    static get Builder() {
        class Builder {
            constructor(user, onUserSelection, selected) {
                this.user = user;
                this.onUserSelection = onUserSelection;
                this.isSelected = selected;
            }
            build() {
                const user = this.user;
                let builder = new ListItem.Builder();
                builder = builder
                    .setTitleWithLink(`${user.first_name} ${user.last_name}`, `/users/user/${user.user_id}`)
                    .setDescription(
                        <div>
                            <Row>{user.role}</Row>
                        </div>
                    )
                    .setLeftIcon(<Checkbox checked={this.isSelected} onChange={e => this.onUserSelection(user.user_id, e.target.checked)}/>)
                    .setRightTag(user.status, user.status==='ACTIVE'?'green':'red');
                return builder.build();
            }
        }
        return Builder;
    }
}
