import React, { Component } from 'react';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';

import { verifyEmail } from '../actions/loginActions';
import ConnectLoading from '../../../components/ConnectLoading';


class EmailVerifyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    }
  }

  componentDidMount = async () => {
    message.loading('Verifying your email, please wait...', 0);
    const results = await verifyEmail(this.props.email, this.props.code);
    message.destroy();
    if(results.err && results.err.message){
      message.error(results.err.message);
    }else{
      message.success('Email is successfully verified. Please login to continue');
    }
    this.props.history.push('/');
  };


  render() {
    return <ConnectLoading />
  }
}

export default withRouter(EmailVerifyPage);