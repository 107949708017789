import { Map } from 'immutable';
import { ORG_ACTIONS } from '../actions/actionTypes';
import * as SearchUtils from '../../../utils/search-utils';

const initialState = new Map({
    generalRequirements: new Map(),
    siteRequirements: new Map(),
});

export default function orgState(state: any = initialState, action: ActionType) {
    switch (action.type) {
        case ORG_ACTIONS.GENERAL_REQUIRENMENTS:
            {
                const orgId = action.payload.orgId;
                const requirements = action.payload.requirements;
                const indexes = SearchUtils.buildIndex(requirements, "id", ["title"]);
                let generalRequirements = state.get('generalRequirements');
                generalRequirements = generalRequirements.set(orgId, { requirements, indexes });
                return state.set('generalRequirements', generalRequirements);
            }
        case ORG_ACTIONS.SITE_REQUIRENMENTS:
            {
                const orgId = action.payload.orgId;
                const requirements = action.payload.requirements;
                const indexes = SearchUtils.buildIndex(requirements, "id", ["title", "assigned_to"]);
                let siteRequirements = state.get('siteRequirements');
                siteRequirements = siteRequirements.set(orgId, { requirements, indexes });
                return state.set('siteRequirements', siteRequirements);
            }
        default:
            return state
    }
}  