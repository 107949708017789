import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import DocumentsHome from './containers/DocumentsHome';
import DocumentUploadRequirementView from './containers/DocumentUploadRequirementView';
import DocumentReviewView from './containers/DocumentReviewView';
import DocumentDetailView from './containers/DocumentDetailView';

export class DocumentDashboard extends Component {

    getView = (route: any) => {
        switch (route.match.params.page) {
            case 'document_upload':
                return (<DocumentUploadRequirementView reqId={route.match.params.param} />);
            case 'site_document_upload':
                return (<DocumentUploadRequirementView reqId={route.match.params.param} isSiteReq={true} />);
            case 'document_review':
                return (<DocumentReviewView reqId={route.match.params.param} />);
            case 'site_document_review':
                return (<DocumentReviewView reqId={route.match.params.param} isSiteReq={true} />);
            case 'user_document':
                return (<DocumentDetailView docId={route.match.params.param} isUserDoc={true} />);
            case 'contractor_document':
                return (<DocumentDetailView docId={route.match.params.param} isUserDoc={false} />);
            default:
                return (<DocumentsHome />);
        }
    }

    render() {
        return (
            <Switch>
                <Route path={'/documents/:page/:param'} render={this.getView} />
                <Route path={'/documents'} render={this.getView} />
                <Redirect from='/documents' to='/documents' />
            </Switch>
        )
    }

}

export default withRouter(DocumentDashboard);