import { getApi, ContractorApi, postApi, putApi } from "../../../utils/api-utils";
import { message } from "antd";
import * as CommonUtils from '../../../utils/common-utils';
import { CONFIG } from '../../../config/app-config';
import { DOCUMENT_ACTIONS } from '../actions/actionTypes';

export const DOC_REQUEST_STATUS = {
    EXPIRED: -2,
    REJECTED: -1,
    NEW: 0,
    COMPLETED: 50,
    VERIFIED: 100
}

export async function addDocument(contractorId, data, file) {
    try {
        const response = await postApi(ContractorApi.DOCUMENT_API, '/contractor_document', data);
        await CommonUtils.uploadFile(file, `${response.docId}.pdf`, CONFIG.documentsUploadsBucket, contractorId);
        return response.docId;
    } catch (e) {
        console.log(e);
        message.error("Error in adding document");
    }
}

export async function updateDocumentDetails(docId, data) {
    try {
        await putApi(ContractorApi.DOCUMENT_API, '/contractor_document_details', {docId, ...data});
    } catch (e) {
        console.log(e);
        message.error("Error in updating document");
    }
}


export async function addDocumentVersion(contractorId, docId, expiryDate, file) {
    try {
        await CommonUtils.uploadFile(file, `${docId}.pdf`, CONFIG.documentsUploadsBucket, contractorId);
        await putApi(ContractorApi.DOCUMENT_API, '/contractor_document', { expiryDate, docId });
    } catch (e) {
        console.log(e);
        message.error("Error in adding document");
    }
}

export async function addInitialDocumentVersion(docId, expiryDate) {
    try {
        await putApi(ContractorApi.DOCUMENT_API, '/contractor_document', { expiryDate, docId, version:1 });
    } catch (e) {
        console.log(e);
        message.error("Error in adding initial document version");
    }
}

export async function addUserDocument(userId, data, file) {
    try {
        const response = await postApi(ContractorApi.DOCUMENT_API, '/user_document', data);
        await CommonUtils.uploadFile(file, `${response.docId}.pdf`, CONFIG.userUploadsBucket, `${userId}/documents`);
        return response.docId;
    } catch (e) {
        console.log(e);
        message.error("Error in adding user document");
    }
}

export async function updateUserDocumentDetails(docId, data) {
    try {
        await putApi(ContractorApi.DOCUMENT_API, '/user_document_details', {docId, ...data});
    } catch (e) {
        console.log(e);
        message.error("Error in updating user document");
    }
}

export async function addUserDocumentVersion(userId, docId, expiryDate, file) {
    try {
        await CommonUtils.uploadFile(file, `${docId}.pdf`, CONFIG.userUploadsBucket, `${userId}/documents`);
        await putApi(ContractorApi.DOCUMENT_API, '/user_document', { expiryDate, docId });
    } catch (e) {
        console.log(e);
        message.error("Error in adding user document");
    }
}

export async function addInitalUserDocumentVersion(docId, expiryDate) {
    try {
        await putApi(ContractorApi.DOCUMENT_API, '/user_document', { expiryDate, docId, version:1 });
    } catch (e) {
        console.log(e);
        message.error("Error in adding initial document version");
    }
}


export function loadContractorDocs() {
    return async function (dispatch, getState) {
        try {
            const result = await getApi(ContractorApi.DOCUMENT_API, '/contractor_documents');
            await dispatch({
                type: DOCUMENT_ACTIONS.DOCUMENTS_LIST,
                payload: result.docList
            });
        } catch (e) {
            console.log(e);
            message.error("Error in getting contractor documents");
        }
    }
}

export async function getContractorDoc(docId) {
    try {
        const result = await getApi(ContractorApi.DOCUMENT_API, '/contractor_document', { queryStringParameters: { docId } });
        return result.document;
    } catch (e) {
        console.log(e);
        message.error("Error in getting contractor document");
    }
}

export async function getContractorDocVersions(docId) {
    try {
        const result = await getApi(ContractorApi.DOCUMENT_API, '/contractor_document_versions', { queryStringParameters: { docId } });
        return result.versions;
    } catch (e) {
        console.log(e);
        message.error("Error in getting contractor document versions");
    }
}

export function loadUserDocs() {
    return async function (dispatch, getState) {
        try {
            const result = await getApi(ContractorApi.DOCUMENT_API, '/user_documents');
            await dispatch({
                type: DOCUMENT_ACTIONS.USER_DOCUMENTS_LIST,
                payload: result.docList
            });
        } catch (e) {
            console.log(e);
            message.error("Error in getting user documents");
        }
    }
}

export async function getUserDoc(docId) {
    try {
        const result = await getApi(ContractorApi.DOCUMENT_API, '/user_document', { queryStringParameters: { docId } });
        return result.document;
    } catch (e) {
        console.log(e);
        message.error("Error in getting user document");
    }
}

export async function getUserDocVersions(docId) {
    try {
        const result = await getApi(ContractorApi.DOCUMENT_API, '/user_document_versions', { queryStringParameters: { docId } });
        return result.versions;
    } catch (e) {
        console.log(e);
        message.error("Error in getting user document versions");
    }
}

export async function getOrgDocRequest(reqId, isSiteReq) {
    try {
        const data = await getApi(ContractorApi.DOCUMENT_API, `/org_doc_request`, { queryStringParameters: { reqId, isSiteReq } });
        return data.request;
    } catch (e) {
        console.log(e);
    }
}

export async function completeOrgDocRequest(reqId, docId, expiryDate, docUploadId, isSiteReq, version) {
    try {
        const data = await postApi(ContractorApi.DOCUMENT_API, `/org_doc_request`, { reqId, docId, expiryDate, docUploadId, isSiteReq, version });
        return data.request;
    } catch (e) {
        console.log(e);
    }
}

export async function getDocReview(reqId, isSiteReq) {
    try {
        const data = await getApi(ContractorApi.DOCUMENT_API, `/doc_review`, { queryStringParameters: { reqId, isSiteReq } });
        return data.docReview;
    } catch (e) {
        console.log(e);
    }
}

export async function submitDocReview(reqId, isSiteReq, docReviewId ) {
    try {
        await putApi(ContractorApi.DOCUMENT_API, `/doc_review_complete`, { reqId, isSiteReq, docReviewId } );
    } catch (e) {
        console.log(e);
    }
}

export async function getUserDocLink(docId, s3Version) {
    try {
        const data = await getApi(ContractorApi.DOCUMENT_API, `/user_document_url`, { queryStringParameters: { docId, s3Version } });
        return data.url;
    } catch (e) {
        console.log(e);
    }
}
export async function getContractorDocLink(docId, s3Version) {
    try {
        const data = await getApi(ContractorApi.DOCUMENT_API, `/contractor_document_url`, { queryStringParameters: { docId, s3Version } });
        return data.url;
    } catch (e) {
        console.log(e);
    }
}