import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, Tabs } from 'antd';
import { setSelectedMenu } from '../../../actions/commonActions';
import { setBreadcrumb } from '../../../actions/commonActions';
import ContractorDocumentView from './ContractorDocumentView';
import UserDocumentView from './UserDocumentView';

const TabPane = Tabs.TabPane;

class DocumentsHome extends Component {

    constructor(props) {
        super(props);
        this.props.setSelectedMenu(['documents']);
        this.props.setBreadcrumb("Documents", [
            { route: '/documents/contractor_documents', label: 'Documents'}
        ]);
    }

    render() {
        if (this.props.isAdmin) {
            return (
                <Card>
                    <Tabs className='epar__details__view'>
                        <TabPane tab="Business Documents" key="0">
                            <ContractorDocumentView />
                        </TabPane>
                        <TabPane tab="My Documents" key="1">
                            <UserDocumentView/>
                        </TabPane>
                    </Tabs>
                </Card>
            );
        }
        else {
            return (
                <UserDocumentView/>
            );
        }
    }
}

function mapStateToProps(state, props) {
    return {
        isAdmin: state.getIn(['loginUserState', 'isAdmin']),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedMenu: bindActionCreators(setSelectedMenu, dispatch),
        setBreadcrumb: bindActionCreators(setBreadcrumb, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(DocumentsHome);