import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Form, Modal, Input, message, TreeSelect } from 'antd';

import { addNewUser } from '../actions/userActions';

const FormItem = Form.Item;
const TreeNode = TreeSelect.TreeNode;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class NewUserModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creatingUser: false
        }
    }

    handleCancel = () => {
        this.props.form.resetFields();
        this.props.onCancel();
    }

    handleOk = () => {
        const { form } = this.props;
        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }
            await this.setState({ creatingUser: true });
            message.loading('Creating user...');
            const result = await addNewUser(values.firstName, values.lastName, values.email, values.role, values.organisations);
            message.destroy();
            if (result.error) {
                message.error(result.error, 5);
                await this.setState({ creatingUser: false });
            } else {
                message.success('User created successfully');
                form.resetFields();
                this.props.onSave();
            }
        });
    }

    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Modal
                title={"Add New User"}
                visible={this.props.visible}
                okText={"Save"}
                cancelText={"Cancel"}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                destroyOnClose={true}
                okButtonProps={{ disabled: this.state.creatingUser }}
                cancelButtonProps={{ disabled: this.state.creatingUser }}
            >
                <Form layout='horizontal'>
                    <FormItem {...formItemLayout} label={"First Name"}>
                        {
                            getFieldDecorator('firstName', { rules: [{ required: true, message: 'Please enter first name' }] })(
                                <Input maxLength={100} placeholder={"Enter first name"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Last Name"}>
                        {
                            getFieldDecorator('lastName', { rules: [{ required: true, message: 'Please enter last name' }] })(
                                <Input maxLength={100} placeholder={"Enter last name"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Email"} extra="This email address will be the user's new username.">
                        {
                            getFieldDecorator('email', {
                                rules: [
                                    { type: "email", message: "Please enter a valid E-mail!" },
                                    { required: true, message: 'Please enter email' }]
                            })(
                                <Input maxLength={100} placeholder={"Enter email"} />
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Organisation"} extra="Assign this user to an organisation they will be working with.">
                        {
                            getFieldDecorator('organisations')(
                                <TreeSelect
                                    showSearch
                                    placeholder="Please select"
                                    allowClear
                                    multiple
                                    treeDefaultExpandAll
                                >
                                    {this.props.orgList.map(org => <TreeNode value={org.org_id} title={org.org_name} key={org.org_id} />)}

                                </TreeSelect>
                            )
                        }
                    </FormItem>
                    <FormItem {...formItemLayout} label={"Role"}>
                        {
                            getFieldDecorator('role')(
                                <Input maxLength={100} placeholder={"Enter role of the user"} />
                            )
                        }
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        orgList: state.getIn(['loginUserState', 'orgList'])
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NewUserModal));